import { FC, useEffect, useState } from 'react';
import { ServiceRequest } from 'roslib';
import { useServiceCaller } from '../../services/Ros/ServiceCaller';
import { useConnection } from '../../services/Ros/Connection';

interface TeleopToggleProps {
  activateTeleop: boolean;
  collisionOveride:boolean;
}

export const TeleopToggle: FC<TeleopToggleProps> = ({ activateTeleop,collisionOveride }) => {
  const { callService } = useServiceCaller();
  const readyState = useConnection();
  const [previous, setPrevious] = useState(false);

  useEffect(() => {
    const request: ServiceRequest = { data: activateTeleop };

    if (activateTeleop === previous || collisionOveride === true) {
      return;
    } else {
      setPrevious(activateTeleop);
    }

    const triggerServiceCall = async () => {
      if (readyState) {
        try {
          // Set a timeout of 10000 ms (10 seconds) for the service call
          const result = await callService(request)
          console.log('Teleop service result:', result);
        } catch (error) {
          console.error('Failed to call teleop service:', error);
        }
      }
    };

    // Delay the call slightly to ensure service initialization
    const timeout = setTimeout(triggerServiceCall, 100);

    return () => clearTimeout(timeout);
  }, [activateTeleop, callService, readyState]);

  return null;
};

export default TeleopToggle;
