import React, { useState, useEffect } from 'react';
import { useFetch } from '../../services/Requests/useFetch';
import '../../css/MissionScheduler.css';
import { useObserver } from '../../utilities/http/routing/ObserverContext';
import { Button, Spinner } from 'react-bootstrap';
import { ConnectionMessage } from '../../core/Messages/ConnectionMessage';
import { faPlugCircleMinus } from '@fortawesome/free-solid-svg-icons';

const defaultSettings = {
  email: '',
  activeHours: false,
  startTime: '07:00',
  stopTime: '20:00',
  Operating: false,
};

interface MissionSchedule {
  mission_id: string;
  name: string;
  time: string;
  daysOfWeek: string[];
}

interface LoadSettings {
  email: string;
  activeHours: boolean;
  startTime: string;
  stopTime: string;
  Operating: boolean;
}

interface Props {
  submit: boolean;
  onCancel: (cancel: boolean) => void;
}

const options: RequestInit = {
  method: "GET",
};
const postOptions: RequestInit = {
  method: "POST",
};

const MissionScheduler: React.FC<Props> = ({ submit,onCancel }) => {
  const [missionSchedules, setMissionSchedules] = useState<MissionSchedule[]>([]);
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const { isObserver } = useObserver();
  const [settings, setSettings] = useState<LoadSettings>(defaultSettings);
  const [loaded, setLoaded] = useState(false);

  const mission_schedule_plans = useFetch<MissionSchedule[]>('/api/missions/mission_schedule', options);
  const cancelMission = useFetch('/api/missions/cancel_scheduled_mission', postOptions);
  const { state: loadState, get: loadSettings, abortRequest: loadAbort } = useFetch<LoadSettings>('/api/missions/load_settings', options);
  
  const toggleOperation = useFetch('/api/missions/toggle_operation', postOptions);

  useEffect(() => {
    const fetchMissionSchedules = async () => {
      await mission_schedule_plans.get();
    };
    fetchMissionSchedules();

    return () => {
      if (mission_schedule_plans.abortRequest) {
        mission_schedule_plans.abortRequest();
      }
    };
  }, []);

  useEffect(() => {
    loadSettings();
    return () => {
        if (loadAbort) {
            loadAbort();
        }
    };
  }, []);

  useEffect(() => {
    const { status, data } = loadState;
    if (status === 'fetched' && data) {
        setSettings(data);
        setLoaded(true)
    }
  }, [loadState]);


  useEffect(() => {
    if (submit==true) {
      // console.log("Submit Change")
      const fetchMissionSchedules = async () => {
        await mission_schedule_plans.get();
      };
      fetchMissionSchedules();

      // return () => {
      //   if (mission_schedule_plans.abortRequest) {
      //     console.log('abort')
      //     mission_schedule_plans.abortRequest();
      //   }
      // };
    }
  }, [submit]);

  useEffect(() => {
    const { status, data } = mission_schedule_plans.state;
    // console.log(status)
    if (status === 'fetched' && data && data.length > 0) {
      setMissionSchedules(data);
    }
  }, [mission_schedule_plans.state]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);


  const handleToggleOperation = async () => {
    if (isObserver){
      console.log(`Operator is an Observer, unable to Pause Missions`)
      return
    }
    await toggleOperation.post()
    await loadSettings()
  }

  const handleCancelMission = async (mission_id: string, time: string, day: string) => {
    if (isObserver){
      console.log(`Operator is an Observer, unable to Cancel Missions`)
      return
    }
    const cancelData = { mission_id, time, day };
    
    await cancelMission.post(cancelData);
    setMissionSchedules(missionSchedules.map(schedule => {
      if (schedule.mission_id === mission_id && schedule.time === time) {
        return { ...schedule, daysOfWeek: schedule.daysOfWeek.filter(d => d !== day) };
      }
      return schedule;
    }).filter(schedule => schedule.daysOfWeek.length > 0));
    onCancel(true)
  };

  const formatTime = (date: Date) => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  const formatDay = (date: Date) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[date.getDay()];
  };

  const getTimeSlotIndex = (time: string) => {
    const [hours] = time.split(':').map(Number);
    return `${hours.toString().padStart(2, '0')}:00`;
  };

  const renderCellContent = (hour: number, day: string) => {
    const time = `${hour.toString().padStart(2, '0')}:00`;
    const mission = missionSchedules.find(schedule =>
      getTimeSlotIndex(schedule.time) === time && schedule.daysOfWeek.includes(day)
    );

    if (mission) {
      return (
        <div className="mission-block">
          <button onClick={() => handleCancelMission(mission.mission_id, mission.time, day)}>
            {mission.name}
          </button>
        </div>
      );
    }
    return null;
  };

  if (mission_schedule_plans.state.status === "fetching"){
    return (
      <div className="modal-content scheduler-body">
        <div className="mission-scheduler">
          <h2>Schedule</h2>
          <div className="current-time">
            {formatDay(currentTime)} {formatTime(currentTime)}
          </div>
            <Spinner animation={'border'} />
        </div>
      </div>)
  }else if (mission_schedule_plans.state.status === "error"){
   return (
    <div className="modal-content scheduler-body">
      <div className="mission-scheduler">
        <h2>Schedule</h2>
        <div className="current-time">
          {formatDay(currentTime)} {formatTime(currentTime)}
        </div>
          <ConnectionMessage icon={faPlugCircleMinus} text='Could not connect' colour='dark' />
      </div>
    </div>)
  } 

  return (
    <div className="modal-content scheduler-body">
      <div className="mission-scheduler">
        <h2>Schedule</h2>
        <div className="current-time">
          {formatDay(currentTime)} {formatTime(currentTime)}
        </div>
          <Button variant={settings.Operating ? "danger" : "primary"} className="flex-fill" onClick={() => handleToggleOperation()} disabled={!loaded}>
              {settings.Operating ? "Pause Scheduled Runs" : "Resume Scheduled Runs"}
          </Button>
          <br></br>
          
          <div className={`schedule-grid ${!settings.Operating ? 'disabled' : ''}`}>
            <div className="day-header"></div>
            {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map(day => (
              <div key={day} className="day-header">{day}</div>
            ))}
            {[...Array(24)].map((_, hour) => (
              <React.Fragment key={hour}>
                <div className="time-slot">{hour}:00</div>
                {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map(day => (
                  <div key={day + hour} className="schedule-cell">
                    {renderCellContent(hour, day)}
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
       </div>
    </div>
  );
};

export default MissionScheduler;
