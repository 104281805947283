import { useEffect, useRef, useState } from "react";
import { Button, Card, Image, Form, Row, Col, Spinner } from "react-bootstrap";
import { useFetch } from "../../services/Requests/useFetch";
import { ConnectionMessage } from "../../core/Messages/ConnectionMessage";
import { faPlugCircleMinus } from "@fortawesome/free-solid-svg-icons";

export interface MissionLogData {
    mission: number;
    uid: number;
    start: string;
    runtime: number;
    error: [boolean,string];
    assets: string[];
}

interface ImageData {
    id: string;
    url: string;
    title: string;
}


function checkMissionStatus(status: string | null | undefined): string {
    switch (status) {
        case 'r0':
            return "Recording/Saving couldn't start";
        case 'r1':
            return "Recording error";
        case 's0':
            return "Saving couldn't start";
        case 's1':
            return "Save error";
        case 'p0':
            return "Mission tried to start";
        case 'p1':
            return "Unknown error";
        case 'p2':
            return "Undocking timeout";
        case 'p3':
            return "Manually cancelled mission";
        case 'p4':
            return "Out of position error";
        case 'p5':
            return "General error";
        case 'start':
            return "Starting";
        case 'message':
            return "Update";
        case 'complete':
            return "Mission complete";
        default:
            return "Unknown status";
    }
}


const options: RequestInit = {
    method: "GET"
}

export default function MissionLogBody() {
    // const [missions, setMissions] = useState<MissionLogData[]>()
    const [images, setImages] = useState<ImageData[]>([]);
    const [loading, setLoading] = useState<boolean>(true)
    const [loadingImages, setLoadingImages] = useState<boolean>(false)
    const [selectedMission, setSelectedMission] = useState<MissionLogData>();
    const [missionIdSearch, setMissionIdSearch] = useState("");
    const [filteredMissions, setFilteredMissions] = useState<MissionLogData[]>([]);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const getMissionLogs = useFetch<MissionLogData[]>('/api/missions/get_mission_logs', options);
    const getMissionImages = useFetch<{assets: ImageData[]}>('/api/missions/get_mission_log_images', options)

    const missionTitles = useFetch<[string, string][]>('/api/missions/missionplans', options);
    const [missionList, setMissionList] = useState<Record<string, string>>({});
    const missionListRef = useRef(missionList);
    
    useEffect(() => {
        missionTitles.get();
        return () => {
            missionTitles.abortRequest?.();
        };
    }, []);

    useEffect(() => {
        const { status, data } = missionTitles.state;
        
        if (status === 'fetched' && Array.isArray(data)) {
            const dictionary = Object.fromEntries(data);
            setMissionList(dictionary);
            missionListRef.current = dictionary; 
        }
    }, [missionTitles.state.data]);
    


        useEffect(() => {
            getMissionLogs.get();
            return () => {
                getMissionLogs.abortRequest?.();
            };
        }, []);

        useEffect(() => {
            if (selectedMission){
                setImages([])
                setLoadingImages(true)
                const uid = selectedMission.uid
                getMissionImages.get([
                    { param: 'uid', value: uid }
                ]);
        

            }
        }, [selectedMission]);

        useEffect(() => {
            const { status, data } = getMissionImages.state;
            
            if (status === 'fetched' && data) {
                setImages(data['assets']);
                setLoadingImages(false)
            }
        }, [getMissionImages.state.data]);

        useEffect(() => {
            const { status, data } = getMissionLogs.state;
            
            if (status === 'fetched' && data) {
                setLoading(false)
                setFilteredMissions(data ?? []);
            }
        }, [getMissionLogs.state.data]);
        
        
        useEffect(() => {
            if (!getMissionLogs.state.data) return; // Ensure data is available before filtering
        
            setFilteredMissions(getMissionLogs.state.data.filter((mission) => {
                const missionRefValue = missionListRef.current[mission.mission];
        
                const matchesId = missionIdSearch 
                    ? missionRefValue !== undefined && missionRefValue.toString().includes(missionIdSearch)
                    : true;
        
                const matchesDate = startDate && endDate
                    ? new Date(mission.start) >= new Date(startDate) && new Date(mission.start) <= new Date(endDate)
                    : true;
        
                return matchesId && matchesDate;
            }));
        }, [missionIdSearch, startDate, endDate, getMissionLogs.state.data]); // Dependencies ensure it re-runs when any filter changes
        
    
        const handleDownload = (image: ImageData) => {
            const link = document.createElement('a');
            link.href = image.url;
            link.download = `${image.id || 'download'}`;
            link.click();
        };

    if (missionTitles.state.status === "error"){
        return (<div className="modal-content waypoint-form-body">
                    <ConnectionMessage icon={faPlugCircleMinus} text='Could not connect' colour='dark' />
                </div>)
    }

    return (
        <Row className="h-screen">
        {/* Left Section: Selected Mission Images */}
        <Col md={8} className="p-4 bg-light">
            <div
            className="image-container"
            style={{ minHeight: "70vh", maxHeight: "70vh", overflowY: "auto" }}
            >
            {loadingImages ? (
                <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100%" }}
                >
                    <Spinner animation="border" />
                </div>
            ) : images.length === 0 ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                    <p>No images available</p>
                </div>
            ) : (
                images.map((image, index) => (
                <Card key={index} className="mb-3">
                    <Card.Body>
                    <Card.Title>{image.id.split("_")[0]}</Card.Title>
                    <div className="image-wrapper">
                        <Image src={image.url} fluid thumbnail />
                    </div>
                    <Button variant="primary" onClick={() => handleDownload(image)}>
                        Download Image
                    </Button>
                    </Card.Body>
                </Card>
                ))
            )}
            </div>

        </Col>
        
        {/* Right Section: Mission Cards & Search */}
        <Col md={4} className="p-4 overflow-auto">
            <Form>
            <Form.Group controlId="missionIdSearch">
                <Form.Label>Search by Mission</Form.Label>
                <Form.Control
                type="text"
                placeholder="Enter Mission"
                value={missionIdSearch}
                onChange={(e) => setMissionIdSearch(e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="startDate">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="endDate">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                />
            </Form.Group>
            </Form>
            <div style={{
                maxHeight: "45vh",
                minHeight: "45vh",
                overflowY: "auto",
                border: "1px solid #ccc",
                borderRadius: "5px",
                padding: "10px",
                backgroundColor: "#f9f9f9",
                }}>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                    <Spinner animation="border" />
                </div>
                ) : filteredMissions && filteredMissions.length > 0 ? (
                [...filteredMissions] // Create a shallow copy before sorting
                    .sort((a, b) => new Date(b.start).getTime() - new Date(a.start).getTime())
                    .map((mission, index) => (
                    <Card key={index} className="mt-3 cursor-pointer" onClick={() => setSelectedMission(mission)}>
                        <Card.Body>
                        <h5>{missionListRef.current[mission.mission] ?? "Unknown"}</h5>
                        <p>
                            Start:{" "}
                            {mission.start
                            ? new Date(mission.start + "Z").toLocaleString()
                            : "No Start Time Available"}
                        </p>
                        <p>
                            Runtime:{" "}
                            {mission.runtime
                            ? `${String(Math.floor(mission.runtime / 3600)).padStart(2, "0")}:${String(
                                Math.floor((mission.runtime % 3600) / 60)
                                ).padStart(2, "0")}:${String(Math.round(mission.runtime % 60)).padStart(2, "0")}`
                            : "No Runtime Available"}
                        </p>
                        <p className={mission.error && Array.isArray(mission.error) && mission.error.length === 2 && mission.error[0] ? "text-danger" : "text-success"}>
                            Status:{" "}
                            {mission.error && Array.isArray(mission.error) && mission.error.length === 2
                                ? mission.error[0]
                                ? checkMissionStatus(mission.error[1]) || "Error occurred"
                                : "Success"
                                : "Success"}
                        </p>
                        <Button onClick={() => setSelectedMission(mission)}>View Details</Button>
                        </Card.Body>
                    </Card>
                    ))
                ) : (
                <p>No missions available.</p>
                )}


            </div>
        </Col>
        </Row>
    );
}
