import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface TimerInputProps {
  onChange: (totalSeconds: number) => void;
}

const TimerInput: React.FC<TimerInputProps> = ({ onChange }) => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const handleChange = (value: number, type: "hours" | "minutes" | "seconds") => {
    let newHours = hours;
    let newMinutes = minutes;
    let newSeconds = seconds;

    if (type === "hours") {
      newHours = Math.max(0, value);
      setHours(newHours);
    } else if (type === "minutes") {
      newMinutes = Math.max(0, Math.min(59, value));
      setMinutes(newMinutes);
    } else {
      newSeconds = Math.max(0, Math.min(59, value));
      setSeconds(newSeconds);
    }

    const totalSeconds = newHours * 3600 + newMinutes * 60 + newSeconds;
    onChange(totalSeconds);
  };

  return (
    <div className="input-group text-center" >
      <OverlayTrigger placement="left" overlay={<Tooltip>Hours</Tooltip>}>
        <input
          type="number"
          value={String(hours).padStart(2, "0")}
          onChange={(e) => handleChange(parseInt(e.target.value) || 0, "hours")}
          min="0"
          className="form-control text-center"
          style={{ borderRadius: "5px 0 0 5px", width: "50px", padding: "5px" }}
        />
      </OverlayTrigger>
      <span className="input-group-text">:</span>
      <OverlayTrigger placement="left" overlay={<Tooltip>Minutes</Tooltip>}>
        <input
          type="number"
          value={String(minutes).padStart(2, "0")}
          onChange={(e) => handleChange(parseInt(e.target.value) || 0, "minutes")}
          min="0"
          max="59"
          className="form-control text-center"
          style={{ width: "50px", padding: "5px" }}
        />
      </OverlayTrigger>
      <span className="input-group-text">:</span>
      <OverlayTrigger placement="left" overlay={<Tooltip>Seconds</Tooltip>}>
        <input
          type="number"
          value={String(seconds).padStart(2, "0")}
          onChange={(e) => handleChange(parseInt(e.target.value) || 0, "seconds")}
          min="0"
          max="59"
          className="form-control text-center"
          style={{ borderRadius: "0 5px 5px 0", width: "50px", padding: "5px" }}
        />
      </OverlayTrigger>
    </div>
  );
};

export default TimerInput;
