import { useEffect, useState } from "react";
import { Button, Card, Form, Row, Col, Spinner } from "react-bootstrap";
import { useFetch } from "../../services/Requests/useFetch";
import { ConnectionMessage } from "../../core/Messages/ConnectionMessage";
import { faPlugCircleMinus } from "@fortawesome/free-solid-svg-icons";
import { useObserver } from "../../utilities/http/routing/ObserverContext";
import { BASE_URL, VERIFICATION_PASS } from "../../constants/constants";

interface VideoData {
    id: string,
    date_time: string,
    path: string
}


const options: RequestInit = {
    method: "GET"
}

interface DeleteVideoParams {
    filename: string;
}

export default function VideoManager() {
    const [loading, setLoading] = useState<boolean>(true);
    const [filteredVideos, setFilteredVideos] = useState<VideoData[]>([]);
    const { isObserver } = useObserver();

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const videoTitles = useFetch<VideoData[]>('/api/missions/get_video_titles', options);
    const getDeleteVideo = useFetch<DeleteVideoParams>('/api/missions/delete_video', options);

    
    useEffect(() => {
        videoTitles.get();
        return () => {
            videoTitles.abortRequest?.();
        };
    }, []);
        
    useEffect(() => {
        if (!videoTitles.state.data) return; // Ensure data is available before filtering
        console.log(videoTitles.state.data)  
        
        setFilteredVideos(videoTitles.state.data.filter((video) => {
            const matchesDate = startDate && endDate
                ? new Date(video.date_time) >= new Date(startDate) && new Date(video.date_time) <= new Date(endDate)
                : true;
    
            return matchesDate;
        }));
        setLoading(false)
    }, [startDate, endDate, videoTitles.state.data]); // Dependencies ensure it re-runs when any filter changes
        
    
    const handleDownloadVideo = async (videoName: string) => {
        try {
            const response = await fetch(`${BASE_URL}/api/missions/get_video?asset=${encodeURIComponent(videoName)}`, {
                method: 'GET',
                headers: {
                    'X-Secret-Key': VERIFICATION_PASS
                }
            });
    
            console.log("Response Headers:", response.headers);
            console.log("Content-Length:", response.headers.get("Content-Length"));
    
            if (!response.ok) {
                throw new Error(`Failed to fetch video: ${response.statusText}`);
            }
    
            const blob = await response.blob();
            console.log("Blob size:", blob.size);
    
            if (blob.size < 1000) {  // Debugging: Ensure file is not tiny
                throw new Error("Downloaded file is too small, something is wrong!");
            }
    
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
    
            link.href = url;
            link.download = videoName;
            document.body.appendChild(link);
            link.click();
    
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading video:", error);
        }
    };

    const handleDelete = async (video: VideoData) => {
            const confirmDelete = window.confirm(`Are you sure you want to delete the video titled "${video.id}"?`);
    
            if (confirmDelete) {
                try {
                    await getDeleteVideo.get([{ param: 'filename', value: video.id }]);
                    setLoading(true);
                    videoTitles.get();
                } catch (error) {
                    console.error('Failed to delete image', error);
                }
            }
    };
    

    if (videoTitles.state.status === "error"){
        return (<div className="modal-content waypoint-form-body">
                    <ConnectionMessage icon={faPlugCircleMinus} text='Could not connect' colour='dark' />
                </div>)
    }

    return (
        <Row className="h-screen">
        {/* Left Section: Selected Mission Images */}
            <Col md={8} className="p-4 bg-light">
                <h1>Videos</h1>  
                    <div className="image-container" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                        {loading  ? (
                                <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ height: "100%" }}
                                >
                                    <Spinner animation="border" />
                                </div>
                            ) : filteredVideos.length === 0 ? (
                                <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                                    <p>No images available</p>
                                </div>
                            ) : (
                                filteredVideos.map((video, index) => (
                                <Card key={index} className="mb-3">
                                    <Card.Body>
                                        <Card.Title>{video.id}</Card.Title>
                                        <Button variant="primary" onClick={() => handleDownloadVideo(video.id)}>
                                            Download Video
                                        </Button>
                                        <Button 
                                            variant="danger" 
                                            onClick={() => handleDelete(video)} 
                                            disabled={isObserver}
                                        >
                                            Delete Video
                                        </Button>
                                    </Card.Body>
                                </Card>
                            ))
                        )}
                    </div>
            </Col>
        
        {/* Right Section: Mission Cards & Search */}
            <Col md={4} className="p-4 overflow-auto">
                <Form>
                    <Form.Group controlId="startDate">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="endDate">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Col>
        </Row>
    );
}
