import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import userManager from './userManager';
import { AUTHREQ } from '../../../constants/constants';
import { useObserver } from './ObserverContext';
import { useOperator } from './OperatorContext';


export const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {isOperator, setIsOperator} = useOperator();
  const { isObserver, setIsObserver } = useObserver();  // Get the observer state


  useEffect(() => {
    console.log("Checking user authentication status...");
  
    const handleUserAuthentication = async () => {
      try {
        let user = await userManager.getUser();
  
        // If the user is null, attempt silent sign-in
        if (!user) {
          console.log("User is not authenticated, attempting silent sign-in...");
          user = await userManager.signinSilent();
        }
  
        if (user) {
          console.log("User is authenticated");
          // Process user details here
          const observerClaims = user.profile['groups'];
          
          if (observerClaims.includes('Observer')) {
            setIsObserver(true);
          } else {
            setIsObserver(false);
          }
  
          if (observerClaims.includes('AdvancedOperator')) {
            setIsOperator(true);
          } else {
            setIsOperator(false);
          }
  
          setIsAuthenticated(true);
        } else {
          // Redirect to login if silent sign-in fails
          console.log("Silent sign-in failed, redirecting to login...");
          await userManager.signinRedirect({ state: { targetUrl: rest.location?.pathname } });
        }
      } catch (error) {
        console.error("Error during user authentication check", error);
        userManager.signinRedirect({ state: { targetUrl: rest.location?.pathname } });
      } finally {
        setIsLoading(false);
      }
    };
  
    if (AUTHREQ) {
      setIsLoading(true); // Start loading state
      handleUserAuthentication();
    } else {
      setIsAuthenticated(true);
    }
  }, [rest.location?.pathname]);
  
  

  useEffect(() => {
    // This effect checks the authentication status to avoid re-triggering the redirect
    if (isAuthenticated) {
      setIsLoading(false);
    }
  }, [isAuthenticated]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return null; // Or a loading spinner if preferred
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

AppRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  layout: PropTypes.elementType.isRequired,
};
