import { faChevronDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    useEffect, 
    FC, 
    SyntheticEvent, 
    useState, 
    forwardRef,
    MouseEventHandler,
    Children,
    CSSProperties,
    isValidElement
} from 'react';

import { Dropdown, Form } from 'react-bootstrap';
import { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem';
import { useFetch } from '../../services/Requests/useFetch';
import { LIGHT_PRIMARY, LIGHT_SUCCESS } from '../../constants/constants';
import { useObserver } from '../../utilities/http/routing/ObserverContext';

type Target = {target : { value : string }};

type SelectCallback = (
    eventKey : string | null,
    e : SyntheticEvent<unknown>
) => void;

interface Props {
    onSelect : (id : number) => void;
    activeIndex : number;
}

interface POSTMissionPlans{
    name? : string;
    locations? : [];
    date_created?: string;
}

interface ForwardedRefProps {
    onClick : MouseEventHandler;
    className? : string;
}

interface MenuProps {
    style? : CSSProperties;
    className? : string;
    onSelect : (e : Target) => void;
}

const options : RequestInit = {
    method : "GET"
}


export const MissionPlanSelect : FC<Props> = ({ onSelect,activeIndex }) => {

    //Fetch all the mission plan data.
    const missions = useFetch<string[]>('/api/missions/missionplans', options);
    const mission_test = useFetch<POSTMissionPlans>('/api/mission_plan_data/', { method : "POST", headers : {'Content-Type' : 'application/json'} })
    const [ toggled, setToggled ] = useState<boolean>(false);
    const [ selected, setSelected ] = useState<string | undefined>();
    const [ activeSwitch, setActiveSwitch ] = useState<boolean>(true);
    const { isObserver } = useObserver();

    //When the modal loads get the latest mission plans.

    useEffect(() => {
        missions.get();

        return () => {
            missions.abortRequest && missions.abortRequest();
        }
    }, []);

    useEffect(() => {
        const { state : { status, data } } = missions;

        
        if(status === 'fetched'){
            // console.log(data)
            if(data && data.length > 0){
                console.log(data)
                const id = parseInt(data[0]);
                const name =data[0][1];
                console.log(name)
                setSelected(name);             //When the data loads set the select box to the first mission plan value.
                onSelect(id);
            }
        }
    }, [missions.state.data,activeSwitch]);

    useEffect(() => {

        if (activeIndex === -2 ){
            setActiveSwitch(prev => !prev)

        }
    }, [activeIndex]);

    useEffect(() => {
        if(toggled === true){
            missions.get();     //Init the fetch request.
        }

        return () => {
            if(missions.abortRequest){
                missions.abortRequest();        //Abort any current requests on cleanup.
            }
        }
    }, [toggled]);

    const handleToggled = () => {
        setToggled(!toggled);
    }   

    const handledSelected : SelectCallback = (eventKey, e) => {
        console.log(eventKey);
        if(!eventKey) return;
        // console.log(eventKey&&eventKey);
        
        if(eventKey && eventKey !== "-1"){
            // console.log('Here we are')
            setSelected((e.target as HTMLElement).innerText);
            onSelect(parseInt(eventKey));
        } else {
            mission_test.post({locations: []})
            setSelected(undefined);
            onSelect(parseInt(eventKey));
        }
    }


    //Custom dropdown toggle
    const CustomToggle = forwardRef<HTMLDivElement, ForwardedRefProps>(({ children, onClick}, ref) => (
        <div
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            className="custom-select-dropdown py-2 d-flex align-items-center text-dark mx-3"
        >
            {children}
            <FontAwesomeIcon icon={faChevronDown} className="ms-auto me-2" color={LIGHT_PRIMARY}/>
        </div>
    ));

    const CustomMenu = forwardRef<HTMLDivElement, MenuProps>(({ children, style, className }, ref) => {

        const [value, setValue ] = useState<string>('');

        return ( 
            <div
                ref={ref}
                style={style}
                className={className}
            >
                <Form.Control
                    autoFocus
                    className='mx-3 my-2'
                    style={{width : "calc(100% - 31px)"}}
                    placeholder='Search...'
                    onChange={(e : {target : { value : string }}) => setValue(e.target.value)}
                />
                <ul className="list-unstyled">
                    {
                        Children.toArray(children).filter((child) => {
                            if(isValidElement<DropdownItemProps>(child)){
                                return !value || child.props.children?.toString().toLowerCase().includes(value);
                            }
                        })
                    }
                </ul>
            </div>
        );
    });


    const render = () => {
        if(missions.state.status === 'fetched'){
            // console.log(missions.state.data)
            return missions.state.data?.map((mission, index) => {
                // console.log(mission)
                return (
                    <Dropdown.Item key={index + 1} eventKey={parseInt(mission[0])} className="d-flex">
                        {mission[1]}   
                    </Dropdown.Item>
                );
            });
        }
    }

    if (missions.state.status === "error"){
        return (<Dropdown className="mb-4 mt-3">
                <Dropdown.Toggle as={CustomToggle}>
                <span className={`${selected ?? "text-secondary"}  ms-2`}>
                    N/A
                </span>
                </Dropdown.Toggle>
            </Dropdown>
        );
    }

    return (
        <Dropdown onToggle={handleToggled} onSelect={handledSelected} className="mb-4 mt-3">
            <Dropdown.Toggle as={CustomToggle}>
                <span className={`${selected ?? "text-secondary"}  ms-2`}>
                    {selected ?? "Select a Mission"}
                </span>
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu} style={{width : "calc(100% - 31px)"}} className="mission-planning-menu">
                {!isObserver && <Dropdown.Item key={0} eventKey={-1}>
                    <span className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faPlus} color={LIGHT_SUCCESS} size="1x" className="me-2" />
                        Create Mission Plan
                    </span>
                </Dropdown.Item>}
                <Dropdown.Divider/>
                {render()}
            </Dropdown.Menu>

        </Dropdown>
    );
}
