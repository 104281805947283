import { FC, useEffect, useState } from "react";
import { useMsg } from "../../services/Ros/Subscriber";
import { ROBOT_URL, ROS_PORT } from "../../constants/constants";
import { useFetch } from "../../services/Requests/useFetch";
import { AutodockStopService } from "./AutodockStopService";
import { useServiceCaller } from "../../services/Ros/ServiceCaller";
import { ServiceRequest } from "roslib";
import { useRealtimeUpdates } from "../../services/Requests/websocket";
import { useConnection } from "../../services/Ros/Connection";

interface RobotStateMessage {
  data: number;
}

interface IUpdateMessage { 
  id: number | string;
  prog: number;
  length: number;
  run: boolean;
  start_time?: string | null | undefined;
  return_code?:string;
}


const parseRobotState = (message: RobotStateMessage) => {
  const stateMapping: Record<number, string> = {
    0: "IDLE",
    1: "UNDOCK",
    2: "READY",
    3: "DOCKING",
    4: "AUTO",
    5: "MANUAL",
    6: "NO GPS FIX",
    7: "UNDOCK",
  };

  const stateNumber = message.data;
  const stateString = stateMapping[stateNumber] || "UNKNOWN"; // Default to "UNKNOWN" if stateNumber is not in the mapping
  return stateString;
};

interface IRecordMission {
  t: string;
}

const options: RequestInit = {
  method: "GET",
};

export const DockingControl: FC = () => {
  const dock = useFetch<IRecordMission>('/api/missions/dock', options);
  const [dockingState, setDockingState] = useState(false);
  const [dockingCmd, setDockingCmd] = useState('');

  const [parsedState, setParsedState] = useState<string>("UNKNOWN");
  const [running, setRunning ] = useState<boolean>(false)
  const { callService } = useServiceCaller();
  const connection = useConnection()
  const msg = useMsg();

  const { ws, lastMessage} = useRealtimeUpdates();                                                         //Get the live updates websocket from the context provider.
	const socket = ws

  /**
   * Receives live update messages from the NOTIFY function on the postgres database for mission plans.
   * Listens for a message containing id of the mission plan, current progress, and whether the mission is still running.
   * @param message The message received by the websocket
   */
  const onSocketMessage = (message : MessageEvent) => {
      const data : IUpdateMessage = JSON.parse(message.data);
      console.log(data)
      setRunning(data.run)

  };

  /**
   * Runs when the notification websocket, active index or missionInfo object are changed.
   * Responsible for adding a websocket message listener to listen for the live updates from the database.
   */
  useEffect(() => {
      socket.addEventListener('message', onSocketMessage);

      //Cleanup. Removes listener when this component is destroyed.
      return () => {
          socket.removeEventListener('message', onSocketMessage);
      }

  }, [socket]);

  useEffect(()=>{
    if (lastMessage){
        onSocketMessage(lastMessage);
        console.log(` Last Message:${lastMessage.data}`)
    } 
  },[lastMessage]);

  try {
      useEffect(() => {
          const fetchRobotState = async () => {
              const request: ServiceRequest = {};

                  const result = await callService(request);
                  if (result.success && result.message) {
                      setParsedState(parseRobotState({data: parseInt(result.message)} as RobotStateMessage))
                      console.log(result)
                  } else {
                      console.error("Service returned an unsuccessful response.");
                  }
              
          };
          if (connection){
            fetchRobotState();
          }
      }, [callService,connection]);
  } catch (error) {
      console.error("Service call failed:", error);
  }

  useEffect(() => {
      setParsedState(parseRobotState(msg as RobotStateMessage))
  }, [msg]);


  useEffect(() => {
    if (dockingState && dockingCmd) {
      dock.get([
        { param: 'mission', value: dockingCmd },
        { param: 'host', value: ROBOT_URL },
        { param: 'port', value: ROS_PORT },
        { param: 'dock', value: dockingCmd },
      ]);
      setDockingState(false);
      console.log(dockingCmd === 'dock' ? 'Docking' : 'Undocking');
    }
  }, [dockingState, dockingCmd, dock]);

  const handleDock = (cmd: string) => {
    setDockingCmd(cmd);
    setDockingState(true);
  };


  const getButtonLabel = () => {
    switch (parsedState) {
      case "UNDOCK":
        return "Undocking";
      case "DOCKING":
        return "Docking";
      case "AUTO":
      case "MANUAL":
        return "Driving";
      case "IDLE":
        return "Undock";
      case "READY":
        return "Dock";
      default:
        return "Disconnected";
    }
  };

  const isButtonDisabled = () => {
    return ["UNDOCK", "DOCKING", "AUTO", "MANUAL"].includes(parsedState);
  };

  return (
    <div>
      <button
        onClick={() => handleDock(parsedState === "READY" ? 'dock' : 'undock')}
        disabled={isButtonDisabled() || running}
      >
        {getButtonLabel()}
      </button>

      {/* Show the "Stop Docking" button when the state is "DOCKING" */}
      {parsedState === "DOCKING" && (
        <AutodockStopService/>
      )}
    </div>
  );
};
