/**
 * Author - Daniel Foster
 * Date - 12/09/2022
 * © Wrybill Robotics 2022
 * 
 * This module contains the code for the robot mission planning pop-up modal.
 * This is responsible for fetching mission plan data and displaying it on a map as well as allowing the user to run mission plans and
 * follow their progress as they run.
 */

import { 
    memo, 
    useEffect, 
    useState, 
    MouseEventHandler
} from 'react';
import { Row, Col } from 'react-bootstrap';
// import { PathPlanningMap } from './PathPlanningMap';
import { useFetch } from '../../services/Requests/useFetch';
// import { WaypointList } from './WaypointList';
import { MissionPlanningControlBanner } from './MissionPlanningControlBanner';
// import { orderArrayOfObjects } from '../../utilities/utiltyFunctions';
import { useRealtimeUpdates } from '../../services/Requests/websocket';
// import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';
import { MAP_CENTER, ROBOT_URL, ROS_PORT } from '../../constants/constants'
import { Subscriber } from '../../services/Ros/Subscriber';
import { MapContainer } from '../../core/Maps/MapContainer';
import { GPS_TOPIC } from '../../constants/topics';
import { RobotTrackingMarker } from '../../core/Maps/RobotTrackingMarker';
import AssetSelectionForm from './MissionPlanBuilder';
import MissionScheduler from './MissionScheduler';
import { StopMarkerList } from '../../core/Maps/StopMarkerProvider';
import MissionSchedulerForm from './MissionSchedulerForm';
import { useObserver } from '../../utilities/http/routing/ObserverContext';
import { useNearestBusStop } from './NearestBusStop';

type LastRun = string | null | undefined;

interface locationTypes { 
    id : number;
    name : string;
    description : string;
    icon : string;
    icon_greyscale : string;
    icon_success : string;
}

interface MissionProgress {
    id : number;
    progress : number;
    running : boolean;
    last_run : LastRun;
}

export interface MissionData {
    id : number;
    point_name : string;
    location_type : locationTypes;
    asset_order : number;
    lat : string;
    lng : string;
    enabled : boolean; 
    mission_plan : MissionProgress;
}


export interface IMissionInfo {
    length : number;
    prog : number;
    run : boolean;
    last_run? : LastRun;
    start_time?: LastRun;
}

interface IRunMission {
    t : string;
}

interface IUpdateMessage { 
    // data: string;
    id : number;
    prog : number;
    length: number;
    run : boolean;
    start_time? : LastRun;
}

const options : RequestInit = {
    method : "GET"

}

const postOptions: RequestInit = {
    method: "POST"
}
  


export const PathPlanningBodyMemo = () => {

    const { ws,lastMessage } = useRealtimeUpdates();                                                         //Get the live updates websocket from the context provider.
    const socket = ws
    
    // console.log("PathPlanning")
    
    const fetchMissionPlanData = useFetch<MissionData[]>('/api/missions/missionplans', options);    //Initialize the fetch hook for fetching the mission plan data. This isn't run yet. Just set-up.
    const runMissionPlanData = useFetch<IRunMission>('/api/missions/run', options);              //Initialize the fecth hook for running the mission plan.
    const removeMissionPlan = useFetch('/api/missions/missionplan_remove', postOptions);    //Initialize the fetch hook for fetching the mission plan data. This isn't run yet. Just set-up.
    const continueMissionPlanData = useFetch<IRunMission>('/api/missions/continue_mission', options); 
    const { isObserver } = useObserver();
    const nearestStop = useNearestBusStop();

    const [ timeRefresh, settimeRefresh ] = useState<boolean>(false);
    const [cancelRefresh, setCancelRefresh ] = useState<boolean>(false);
    const [ activeIndex, setActiveIndex ] = useState<number>(-1);    
    const [ missionName, setMissionName ] = useState<string>("")                    //Active mission plan index.
    const [ missionInfo, setMissionInfo ] = useState<IMissionInfo>({length: 1, prog : 0, run : false, last_run : null}); //Mission info such as current markers, progress, whether it is running, and when it was last run.
    // const [ listActiveKey, setListActiveKey ] = useState<AccordionEventKey>(null);               //The active list key. Determines which accordion list item is open.


    const handleRefresh = (submit: boolean) => {
        settimeRefresh(submit)
        settimeRefresh(!submit)
    }

    const handleCancel = (cancel:boolean) => {
        setCancelRefresh(cancel)
        setCancelRefresh(!cancel)
    }

    /**
     * Handles the selection of a mission plan
     * @param id the mission plan id that has been selected.
     */
    const handleSelected = (id : number) => {
        // console.log("end of rabbbit hole "+ id);
        setActiveIndex(id); 
    }

    /**
     * Handles saving to the database when the state and actions of a mission plan waypoint are changed and the user saves this info.
     * TO-DO - implement this. This is to be implemented with the payload stuff. Not needed yet.
     */
    const handleRemove : MouseEventHandler = (_e) => {
        console.log(activeIndex)
        
        if(activeIndex !== -1){
            const mission_id = String(activeIndex)
            removeMissionPlan.post({mission_id});     //Performs a get request with the mission plan id as a query param.
            setActiveIndex(-2)
        }

    }

    /**
     * Runs the mission plan when the run button is clicked. Makes a get request to the server to start the mission plan running server-side.
     * @param _e Mouse click event param. Underscore indicates to typescript this is unused in this function.
     */
    const handleRun : MouseEventHandler = (_e) => {
        console.log(activeIndex)
        if(activeIndex !== -1){
            runMissionPlanData.get([{param : 'mission',   value : String(activeIndex)},
                                    {param : 'host',      value : ROBOT_URL},
                                    {param : 'port',      value : ROS_PORT}]);     //Performs a get request with the mission plan id as a query param.
        }
        setMissionInfo({...missionInfo, prog : 0, length:3, run : true});
    }

    const handleContinue : MouseEventHandler = (_e) => {
        console.log(activeIndex)
        const current_stop = nearestStop?.stop
        const current_zone = nearestStop?.zone
        if(activeIndex !== -1){
            continueMissionPlanData.get([{param : 'mission',   value : String(activeIndex)},
                                    {param : 'host',      value : ROBOT_URL},
                                    {param : 'port',      value : ROS_PORT},
                                    {param : 'stop',      value : String(current_stop)},
                                    {param : 'zone',      value : String(current_zone)}]);     //Performs a get request with the mission plan id as a query param.
        }
        setMissionInfo({...missionInfo, prog : 0, length:3, run : true});
    }

    /**
     * Handles a marker being clicked. Clicking a marker opens the edit section of the accordion list item for this marker 
     * and allows the user to edit the actions performed at this waypoint.
     * @param missionPoint : Object - The mission plan data point object retrieved from the api_missionplandata table. 
     * @returns 
     */
    // const handleMarkerClick = (missionPoint : MissionData) => {
    //     if(missionPoint.asset_order === 1 || missionPoint.mission_plan.running === true){   //Don't do this is if it home or the mission plan is running.
    //         return;
    //     }
    //     if(listActiveKey === (missionPoint.asset_order - 1).toString()){        //If the accordion item is open already then close it.
    //         setListActiveKey(null);
    //     } else {
    //         setListActiveKey((missionPoint.asset_order - 1).toString())         //Set the active list item to the asset order - 1 (asset order starts from 1. Maybe should make it start from zero.)
    //     }
    // }


    /**
     * Receives live update messages from the NOTIFY function on the postgres database for mission plans.
     * Listens for a message containing id of the mission plan, current progress, and whether the mission is still running.
     * @param message The message received by the websocket
     */
    const onSocketMessage = (message : MessageEvent) => {
        const data : IUpdateMessage = JSON.parse(message.data);
        console.log(message.data)
        setActiveIndex(data.id)
        // if(data.id === activeIndex){
        setMissionInfo({...missionInfo, prog : data.prog, run : data.run, length: data.length,start_time:data.start_time});
        console.log(missionInfo)
        // }
    };
    useEffect(()=>{
        if (lastMessage){
            onSocketMessage(lastMessage);
            console.log(` Last Message:${lastMessage.data}`)
        }
        
        
    },[lastMessage]);
    

    /**
     * Runs when the notification websocket, active index or missionInfo object are changed.
     * Responsible for adding a websocket message listener to listen for the live updates from the database.
     */
    useEffect(() => {

        socket.addEventListener('message', onSocketMessage);

        //Cleanup. Removes listener when this component is destroyed.
        return () => {
            socket.removeEventListener('message', onSocketMessage);
        }

    }, [socket, activeIndex, missionInfo]);

    /**
     * Runs when the active index is changed or the fetchMissionPlanData object changes (So it doesnt become stale). 
     * Fetches the data for the selected mission plan.
     */
    useEffect(() => {
        if(activeIndex === -1) return ;

        const { get : getMissionData, abortRequest : abortFetch } = fetchMissionPlanData;     

        if(activeIndex){
            getMissionData([{param : 'mission', value : activeIndex}]);     //Fetch the mission plan data with mission as the query param and activeIndex as the value.
        }

        return () => {
            abortFetch && abortFetch();     //If the modal or page is exited prematurely, clean up any currently running requests.
        }
    }, [activeIndex]);

    const [showMarker, setShowMarker] = useState(true);
    const handleMapClick = (e: google.maps.MapMouseEvent) => {
        setShowMarker((prevShowMarker) => !prevShowMarker);
        console.log('Map clicked at: ', e.latLng?.toString());
    };
    


    return(
        <Row className='h-100'>
            <Col lg={6} className="ps-0">
                <MissionScheduler submit={timeRefresh} onCancel={handleCancel}/>
            </Col>
            <Col lg={3} className="ps-0">
            <Subscriber name={GPS_TOPIC.name} type={GPS_TOPIC.type} rate={GPS_TOPIC.throttle}>
                        <MapContainer
                            id="path-planning-map"
                            zoom={18}
                            center={{ lat: MAP_CENTER[0], lng: MAP_CENTER[1] }}
                            onClick={handleMapClick} // Add onClick handler here
                        >   
                            <StopMarkerList/>
                            {showMarker && <RobotTrackingMarker title="Robot" />}
                        </MapContainer>
            </Subscriber>
            </Col>
            <Col lg={3} className="mt-3 mb-3 path-planning-column">
                <MissionPlanningControlBanner 
                    onSelect={handleSelected} 
                    onRun={handleRun} 
                    onSave={handleRemove}
                    onContinue={handleContinue}
                    activeIndex = {activeIndex} 
                    running={missionInfo.run}
                    timeRun={missionInfo.start_time}
                    progress={Math.round((missionInfo.prog / missionInfo.length) * 100)}
                />
                {!isObserver && <div className='pb-5'>
                    {activeIndex === -1 && <input
                                type="text"
                                className="form-control"
                                value={missionName}
                                onChange={(e) => setMissionName(e.target.value.replace(/\s+/g, ''))}
                                placeholder="Enter Mission Name"
                            />}
                    {activeIndex === -1 && <AssetSelectionForm missionName={missionName} refresh={setActiveIndex}/>}
                    {activeIndex !== -1 &&<MissionSchedulerForm missionId={String(activeIndex)} onScheduleMission={handleRefresh} cancel={cancelRefresh}/>}
                </div>}
            </Col>
        </Row>
    );
}

export const PathPlanningBody = memo(PathPlanningBodyMemo);