import { useEffect, useRef, useState } from 'react';
import { useRealtimeUpdates } from '../services/Requests/websocket';
import { useNotification } from '../services/Notifications/ToastProvider';
import { useFetch } from '../services/Requests/useFetch';


interface IUpdateMessage { 
    id: number | string;
    prog: number;
    length: number;
    run: boolean;
    start_time?: string | null | undefined;
    return_code?:string | null | undefined;
}

const options : RequestInit = {
    method : "GET"
}


function checkMissionStatus(status: string | null | undefined): string {
    switch (status) {
        case 'r0':
            return "Recording/Saving couldn't start";
        case 'r1':
            return "Recording error";
        case 's0':
            return "Saving couldn't start";
        case 's1':
            return "Save error";
        case 'p0':
            return "Mission tried to start";
        case 'p1':
            return "Unknown error";
        case 'p2':
            return "Undocking timeout";
        case 'p3':
            return "Manually cancelled mission";
        case 'p4':
            return "Out of position error";
        case 'p5':
            return "General error";
        case 'start':
            return "Starting";
        case 'message':
            return "Update";
        case 'complete':
            return "Mission complete";
        default:
            return "Unknown status";
    }
}



export const BackendToastNotification = () => {
    const { ws, lastMessage } = useRealtimeUpdates();
    const addToast = useNotification();
    const missions = useFetch<[string, string][]>('/api/missions/missionplans', options);
    const [missionList, setMissionList] = useState<Record<string, string>>({});
    const missionListRef = useRef(missionList);

    useEffect(() => {
        missions.get();
        return () => {
            missions.abortRequest?.();
        };
    }, []);

    useEffect(() => {
        const { status, data } = missions.state;
        
        if (status === 'fetched' && Array.isArray(data)) {
            const dictionary = Object.fromEntries(data);
            setMissionList(dictionary);
            missionListRef.current = dictionary; 
        }
    }, [missions.state.data]);


    // Handler for new websocket message
    const onSocketMessage = (message: MessageEvent) => {
        const data: IUpdateMessage = JSON.parse(message.data);
        // console.log(data.id,data.return_code)
        if (data.id ){
            let response;
            if (data.return_code){
                response = checkMissionStatus(data.return_code)
            }else{
                response= "Update"
            }

            // Trigger a toast notification

            if (data.id != null) {
                let missionId = isNaN(Number(data.id)) ? data.id : Number(data.id);
    
                console.log(`Add Toast ${data.id}, ${data.return_code}, ${typeof missionId}`);
    
                addToast(
                    <div>
                        <div>Mission  : {missionListRef.current[missionId] ?? data.id}</div>
                        <div>Progress : {data.prog}/{data.length}</div>
                        <div>Status   : {response}</div>
                    </div>
                );
            }
            

        }
    };

    useEffect(() => {
        // Process the latest message
        if (lastMessage) {
            onSocketMessage(lastMessage);
        }
    }, [lastMessage]);

    useEffect(() => {
        // Add WebSocket event listener
        ws.addEventListener('message', onSocketMessage);

        // Cleanup the listener on component unmount
        return () => {
            ws.removeEventListener('message', onSocketMessage);
        };
    }, [ws]);

    return (
        <div>
            {/* Component doesn't render anything itself, just listens for updates */}
        </div>
    );
};
