import { useState, useEffect } from 'react';
import WidgetCard from './WidgetCard';
import '../../css/PTZWidget.css';
import { useScreenSize } from '../../services/General/ScreenResolutionProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStop, faArrowLeft, faArrowRight, faArrowUp, faArrowDown, faSearchMinus, faSearchPlus, faVideoCamera } from '@fortawesome/free-solid-svg-icons';
import PTZService from '../../services/Video/PTZService';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useOperator } from '../../utilities/http/routing/OperatorContext';
import TimerInput from '../Timers/TimerInput';
import { useFetch } from '../../services/Requests/useFetch';

const postOptions: RequestInit = {
    method: "POST"
}

interface PTZRecord {
    duration: number
}


export const PTZWidget = () => {
    const { width } = useScreenSize();
    const [ptzSpeed, setPtzSpeed] = useState(0.2);
    const [presetNumber, setPresetNumber] = useState('1');
    const [illegalpresetNumber, setillegalPresetNumber] = useState(1);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [isIllegal, setIsIllegal] = useState<boolean>(false)
    const [timeInput, setTimeInput] = useState<number>(0);
    const [countdown, setCountdown] = useState<number>(0);
    const [isPTZRecording, setIsPTZRecording] = useState(false); // Added state for PTZ recording

    const startPTZRecord = useFetch<PTZRecord>('/api/missions/ptz_recording_video', postOptions);

    const illegalPresets = [37, 44, 45, 46, 47,48, 49, 50, 51, 81, 82, 83, 84, 85, 86, 89, 90, 91, 92, 93, 
        94, 95, 96, 97, 98, 99, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 
        113, 114, 115, 116, 117, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 
        129, 130, 131, 132, 133, 135, 136, 138, 139, 141, 142, 143, 144, 152, 153, 
        157, 159, 160, 161, 162, 163, 164, 168, 169, 170, 171, 172, 173, 174, 181, 
        182, 183, 184, 186, 187, 188, 190, 191, 192, 193, 194]
    const { isOperator } = useOperator();
    let position = 'widget-top-right';

    if (width <= 1024) {
        position = 'widget-top-right';
    }

    useEffect(() => {
        const handleMouseUp = () => {
            if (isMouseDown) {
                setIsMouseDown(false);
                handlePTZStop();
            }
        };

        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isMouseDown]);

    const handlePTZMove = async (direction: string) => {
        try {
            await PTZService.movePTZ(direction, ptzSpeed);
            console.log(`Moving PTZ: ${direction} at speed ${ptzSpeed}`);
        } catch (error) {
            console.error('Failed to move PTZ', error);
        }
    };

    const handlePTZStop = async () => {
        try {
            await PTZService.movePTZ('stop');
            console.log('PTZ Stopped');
        } catch (error) {
            console.error('Failed to stop PTZ', error);
        }
    };

    const handlegotoPreset = async () => {
        try {
            await PTZService.gotoPreset(presetNumber);
            console.log('PTZ went to Preset ' + presetNumber);
        } catch (error) {
            console.error('Failed to go to Preset', error);
        }
    };

    const handlesetPreset = async () => {
        try {
            await PTZService.setPreset(presetNumber);
            console.log('PTZ set to Preset ' + presetNumber);
        } catch (error) {
            console.error('Failed to set Preset', error);
        }
    };

    const handleWipe = async () => {
        try {
            await PTZService.setPreset('51');
            console.log('PTZ set to Preset 51 and wiping');
        } catch (error) {
            console.error('Failed to set Preset', error);
        }
        return
    };

    const handlePTZSnapshot = async () => {
        console.log('Taking PTZ Snapshot');
        await PTZService.snapshotPTZ();
        console.log('Snapshot taken successfully');
    };

    const handleSpeedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPtzSpeed(parseFloat(event.target.value));
    };
    
    const formatTime = (seconds: number): string => {
        const hh = Math.floor(seconds / 3600);
        const mm = Math.floor((seconds % 3600) / 60);
        const ss = seconds % 60;
        return `${String(hh).padStart(2, "0")}:${String(mm).padStart(2, "0")}:${String(ss).padStart(2, "0")}`;
    };
    
    const startCountdown = () => {
        const totalSeconds = timeInput;
        startPTZRecord.post({duration: totalSeconds})
        if (totalSeconds > 0) {
            setCountdown(totalSeconds);
            setIsPTZRecording(true);
            const interval = setInterval(() => {
                setCountdown((prev) => {
                    if (prev <= 1) {
                        clearInterval(interval);
                        setIsPTZRecording(false);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
    };

    const handlePresetNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Math.max(1, Math.min(255, parseInt(event.target.value)));
        if (!illegalPresets.includes(value)){
            setIsIllegal(false)
            setPresetNumber(value.toString());

        }else{
            setIsIllegal(true)
            setillegalPresetNumber(value)
            console.log("Illegal Value")
        }
    };

    const handleDirectionButtonDown = (direction: string) => {
        setIsMouseDown(true);
        handlePTZMove(direction);
    };

    return (
        <WidgetCard position={position}>
            <WidgetCard.Header>
                PTZ Control
            </WidgetCard.Header>
            <WidgetCard.Body>
                <div className="ptz-ctl-box">
                    <div className="ptz-pad-box">
                        <button
                            type="button"
                            className="ptz-goto-home"
                        >
                            <FontAwesomeIcon icon={faStop} onMouseDown={() => handlePTZStop()}/>
                        </button>
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            className="left"
                            onMouseDown={() => handleDirectionButtonDown('left')}
                        />
                        <FontAwesomeIcon
                            icon={faArrowRight}
                            className="right"
                            onMouseDown={() => handleDirectionButtonDown('right')}
                        />
                        <FontAwesomeIcon
                            icon={faArrowUp}
                            className="up"
                            onMouseDown={() => handleDirectionButtonDown('up')}
                        />
                        <FontAwesomeIcon
                            icon={faArrowDown}
                            className="down"
                            onMouseDown={() => handleDirectionButtonDown('down')}
                        />
                    </div>
                </div>
                <div className="ptz-spd-ctl-box">
                    <span className="label">PTZ speed</span>
                    <div className="btn-group btn-group-sm" data-toggle="buttons">
                        <label className="btn btn-default">
                            <input type="radio" name="ptz-speed" value="0.1" onChange={handleSpeedChange} /> slow
                        </label>
                        <label className="btn btn-default">
                            <input type="radio" name="ptz-speed" value="0.2" defaultChecked onChange={handleSpeedChange} /> medium
                        </label>
                        <label className="btn btn-default active">
                            <input type="radio" name="ptz-speed" value="0.35"  onChange={handleSpeedChange} /> fast
                        </label>
                    </div>
                </div>
                <div className="ptz-zom-ctl-box btn-group btn-group-lg" role="group" aria-label="Zoom">
                    <button type="button" className="ptz-zom ptz-zom-ot btn btn-default" onMouseDown={() => handleDirectionButtonDown('zoom-out')}>
                        <FontAwesomeIcon icon={faSearchMinus} />
                    </button>
                    <button type="button" className="ptz-zom ptz-zom-in btn btn-default" onMouseDown={() => handleDirectionButtonDown('zoom-in')}>
                        <FontAwesomeIcon icon={faSearchPlus} />
                    </button>
                </div>
                <div className="disconnect-box">
                    <button type="button" className="form-control btn btn-default" onClick={handlePTZSnapshot}>
                        Snapshot
                    </button>
                    <div className="d-flex align-items-center gap-2">
                        {isPTZRecording ? (
                            <span className="form-control text-center">{formatTime(countdown)}</span>
                        ) : (
                            <TimerInput onChange={setTimeInput} />
                        )}
                        <OverlayTrigger
                            placement="left-start"
                            overlay={<Tooltip id="tooltip-record">{isPTZRecording ? "Recording" : "Start Recording PTZ Video"}</Tooltip>}
                        >
                            <button className="btn btn-primary" onClick={startCountdown} disabled={isPTZRecording}>
                                <FontAwesomeIcon icon={faVideoCamera} />
                            </button>
                        </OverlayTrigger>
                    </div>
                    <OverlayTrigger
                        placement= 'left-start'
                        show={isIllegal}
                        overlay={
                            <Tooltip id="tooltip-email">
                                Preset {illegalpresetNumber} is not allowed 
                            </Tooltip>
                        }
                    >
                        <input
                            type="number"
                            className="form-control"
                            value={presetNumber}
                            onChange={handlePresetNumberChange}
                            placeholder="1"
                            min="1"
                            max="255"
                        />
                    </OverlayTrigger>
                    <button type="button" className="form-control btn btn-default" onClick={handlegotoPreset}>
                        Go to Preset
                    </button>
                    {isOperator && <button type="button" className="form-control btn btn-default" onClick={handlesetPreset}>
                        Set Preset
                    </button>}
                    <button type="button" className="form-control btn btn-default" onClick={handleWipe}>
                        Wipe Lens
                    </button>
                </div>
            </WidgetCard.Body>
        </WidgetCard>
    );
};

export default PTZWidget;
