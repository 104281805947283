import { FC, useEffect, useRef, useState } from "react";
import { RobotState } from "../Vitals/RobotState";
import { StopData } from "../../ZoneTravel/StopTravelBody";
import { useFetch } from "../../../services/Requests/useFetch";
import { useRobotGPS } from "../../../services/Ros/useRobotGPS";

interface GPSPoint {
    lat: number;
    long: number;
}

interface IStopIndicator { 
    name: string;
}

const options: RequestInit = {
    method: "GET"
};

// Function to calculate the distance between two GPS points
const getDistance = (point1: GPSPoint, point2: GPSPoint) => {
    const toRadians = (deg: number) => (deg * Math.PI) / 180;
    const R = 6371; // Earth radius in km
    const dLat = toRadians(point2.lat - point1.lat);
    const dLong = toRadians(point2.long - point1.long);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.cos(toRadians(point1.lat)) * Math.cos(toRadians(point2.lat)) *
              Math.sin(dLong / 2) * Math.sin(dLong / 2);
    
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
};

// Function to remove last stop from each zone
const filterStops = (stops: StopData[]) => {
    const zoneMap = new Map<string, StopData[]>();

    stops.forEach(stop => {
        if (!zoneMap.has(stop.zone)) {
            zoneMap.set(stop.zone, []);
        }
        zoneMap.get(stop.zone)?.push(stop);
    });

    return stops.filter(stop => {
        const zoneStops = zoneMap.get(stop.zone) || [];
        return zoneStops.length > 1 && stop !== zoneStops[zoneStops.length - 1];
    });
};

// Function to get the closest stop
const getClosestStop = (robotPosition: GPSPoint, stops: StopData[] | undefined) => {
    if (!stops || stops.length === 0) return null;

    return stops.reduce((closest, stop) => {
        const stopPosition: GPSPoint = { lat: stop.gps[0], long: stop.gps[1] };
        return !closest || getDistance(robotPosition, stopPosition) < getDistance(robotPosition, { lat: closest.gps[0], long: closest.gps[1] })
            ? stop
            : closest;
    }, stops[0]);
};

export const StopIndicator: FC<IStopIndicator> = ({ name }) => {
    const fetchStopData = useFetch<StopData[]>('/api/missions/get_stops_data', options);
    const stopDataRef = useRef<StopData[]>([]);
    const [currentStop, setCurrentStop] = useState<StopData | null>(null);

    useEffect(() => {
        fetchStopData.get();
        return () => {
            fetchStopData.abortRequest && fetchStopData.abortRequest();
        };
    }, []);

    useEffect(() => {
        const { state: { status, data } } = fetchStopData;
        if (status === 'fetched' && data && data.length > 0) {
            stopDataRef.current = filterStops(data);
        }
    }, [fetchStopData.state]);

    const robotGPS = useRobotGPS();

    useEffect(() => {
        if (!robotGPS || stopDataRef.current.length === 0) return;

        const robotPosition: GPSPoint = { lat: robotGPS.lat, long: robotGPS.long };
        const closestStop = getClosestStop(robotPosition, stopDataRef.current);

        // Check if closest stop is within 2 meters (0.002 km)
        if (closestStop) {
            const stopPosition: GPSPoint = { lat: closestStop.gps[0], long: closestStop.gps[1] };
            const distanceToStop = getDistance(robotPosition, stopPosition);

            if (distanceToStop > 0.002) {
                setCurrentStop(null);
            } else {
                setCurrentStop(closestStop);
            }
        } else {
            setCurrentStop(null);
        }
    }, [robotGPS, stopDataRef.current]);

    const stopDisplayName = currentStop 
        ? currentStop.stop === "stop1" 
            ? "Home" 
            : `${currentStop.zone} - ${currentStop.stop}`
        : (robotGPS ? "Travelling" : "NOGPSFIX");

    return <RobotState name={name} value={stopDisplayName} />;
};
