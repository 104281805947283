import { useState, useEffect } from 'react';
import { convertTimestampToNumber } from '../../utilities/datetime';
import { useFetch } from '../../services/Requests/useFetch';

interface Props {
    running: boolean;
    startTime?: string | null | undefined;
    missionId: number;
}

const postOptions: RequestInit = {
    method: "POST",
};

export const Stopwatch = ({ running, missionId, startTime }: Props) => {
    const [time, setTime] = useState<number>(0);
    const updateDuration = useFetch('/api/missions/update_mission', postOptions);

    useEffect(() => {
        let interval: NodeJS.Timeout | undefined = undefined;
    
        if (running) {
            const timestamp = startTime ? convertTimestampToNumber(startTime) : 0;
            
            let diff = timestamp === 0 ? 0 : new Date().getTime() - timestamp; // Keep UTC-based diff
            console.log(startTime,time,diff)
            setTime(diff);
    
            interval = setInterval(() => {
                setTime((prevTime) => prevTime + 1000);
            }, 1000);
        } else {
            if (time !== 0) {
                console.log(`Completed in ${time}`);
                const missionUpdate = { mission_id: missionId, duration: time };
                updateDuration.post(missionUpdate);
            }
            setTime(0);
            if (interval) {
                clearInterval(interval);
            }
        }
    
        return () => clearInterval(interval);
    }, [running]);
    
    

    const hours = Math.floor(time / 3600000); // No modulus, keeps counting indefinitely
    const minutes = Math.floor((time / 60000) % 60);
    const seconds = Math.floor((time / 1000) % 60);

    return (
        <div className="stopwatch">
            <div className="numbers">
                <span>{time === 0 ? "--" : ("0" + hours).slice(-2)}:</span>
                <span>{time === 0 ? "--" : ("0" + minutes).slice(-2)}:</span>
                <span>{time === 0 ? "--" : ("0" + seconds).slice(-2)}</span>
            </div>
        </div>
    );
};
