import { 
    useContext, 
    createContext, 
    useEffect, 
    useState, 
    useRef, 
    FC 
} from "react";
import { useMobileCheck } from "./useMobileCheck";

interface IScreenContext {
    width : number;
    height : number;
}

export const ScreenContext = createContext<IScreenContext>({width : window.innerWidth, height : window.innerHeight});

export const ScreenResolutionProvider : FC = ({children}) => {

    const isMobile = useMobileCheck();
    const [ width, setWidth ] = useState<number>(window.innerWidth);
    const [ height, setHeight ] = useState<number>(window.innerHeight);
    const debounceTimer = useRef<NodeJS.Timeout | undefined>();

    const handleScreenResize = () => {
        if(debounceTimer.current){
            clearTimeout(debounceTimer.current);
            debounceTimer.current = undefined;
        }
        debounceTimer.current = setTimeout(() => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }, 500);

    }

    const fullScreenCheck = () => {
        if(document.fullscreenElement) return;
        return document.documentElement.requestFullscreen();
    }


    const rotate = async () => {
        try{
            await fullScreenCheck();
        } catch(err){
            console.error(err);
        }

        await window.screen.orientation.lock("landscape");
        
    }



    useEffect(() => {
        
        if(isMobile){
            rotate();
        }

        window.addEventListener('resize', handleScreenResize);

        return () => {
            window.removeEventListener('resize', handleScreenResize);
        }
    }, []);

    return (
        <ScreenContext.Provider value={{width : width, height : height}}>
            {children}
        </ScreenContext.Provider>
    );
} 

export const useScreenSize = () => {
    const context = useContext(ScreenContext);
    if(context === undefined){
       throw new Error("This must be used inside a ScreenContext element"); 
    }

    return context;
}