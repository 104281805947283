import {
    memo,
    useState,
    useEffect,
} from 'react';
import { Row, Col, Form, Button, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { Subscriber } from '../../services/Ros/Subscriber';
import { MapContainer } from '../../core/Maps/MapContainer';
import { GPS_TOPIC } from '../../constants/topics';
import { RobotTrackingMarker } from '../../core/Maps/RobotTrackingMarker';
import { ConnectionMessage } from '../../core/Messages/ConnectionMessage';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { useFetch } from '../../services/Requests/useFetch';
import { useObserver } from '../../utilities/http/routing/ObserverContext';
import { LogViewer } from '../LogViewer';
import { useOperator } from '../../utilities/http/routing/OperatorContext';


const defaultSettings = {
    email: '',
    activeHours: false,
    startTime: '07:00',
    stopTime: '20:00',
};

interface LoadSettings {
    email: string;
    activeHours: boolean;
    startTime: string;
    stopTime: string;
}

const options: RequestInit = {
    method: "GET"
}
const postOptions: RequestInit = {
    method: "POST"
}

export const SettingsPageBodyMemo = () => {
    const [settings, setSettings] = useState(defaultSettings);
    const [showClearModal, setShowClearModal] = useState(false);
    const { isObserver } = useObserver();
    const { isOperator } = useOperator();
    const { state: loadState, get: loadSettings, abortRequest: loadAbort } = useFetch<LoadSettings>('/api/missions/load_settings', options);
    const { state: saveState, post: saveSettings } = useFetch<LoadSettings>('/api/missions/save_settings', postOptions);
    const cleardata = useFetch('/api/missions/clear_data', options);
    

    useEffect(() => {
        loadSettings();
        return () => {
            if (loadAbort) {
                loadAbort();
            }
        };
    }, []);

    useEffect(() => {
        const { status, data } = loadState;
        console.log(status)
        if (status === 'fetched' && data) {
            setSettings(data);
        }
    }, [loadState]);

    const handleSave = async () => {
        try {
            await saveSettings(settings);
            loadSettings(); // Refresh settings after saving
        } catch (error) {
            console.error('Error saving settings:', error);
        }
    };

    const handleChange = (key: string, value: any) => {
        setSettings((prevSettings) => ({
            ...prevSettings,
            [key]: value,
        }));
        console.log(saveState)
    };

    const handleClear = () => {
        cleardata.get();
        console.log('Clearing data');
        setShowClearModal(false);
    };

    const handleShowClearModal = () => {
        setShowClearModal(true);
    };

    const handleCloseClearModal = () => {
        setShowClearModal(false);
    };

    if (loadState.status === 'fetching') {
        return <ConnectionMessage icon={faGear} text='Settings Loading' colour='dark' />
    }else if (loadState.status === 'error'){
        return <ConnectionMessage icon={faGear} text='Settings Could Not Load' colour='dark' />
    }

    return (
        <>
            <Row className='h-100'>
                <Col lg={3} className="ps-0">
                    <Subscriber name={GPS_TOPIC.name} type={GPS_TOPIC.type} rate={GPS_TOPIC.throttle}>
                        <MapContainer
                            id="path-planning-map"
                            zoom={18}
                            center={{ lat: -40.379712032272984, lng: 175.61294976361017 }}
                        >
                            {settings.activeHours && <RobotTrackingMarker title="Robot" />}
                        </MapContainer>
                    </Subscriber>
                </Col>
                <Col lg={8} className="mt-3 mb-3 path-planning-column">
                    <Form>
                    <Form.Group controlId="emailInput">
                        <OverlayTrigger
                            overlay={<Tooltip id="tooltip-email">Enter multiple email addresses separated by commas (e.g., email1@example.com, email2@example.com)</Tooltip>}
                        >
                            <Form.Label>Notification Email(s)</Form.Label>
                        </OverlayTrigger>
                        <Form.Control
                            as="textarea"
                            rows={4}  // Adjust the number of rows to make the box bigger
                            value={settings.email}
                            onChange={(e) => handleChange('email', e.target.value)}
                            placeholder="Enter email addresses separated by commas"
                        />
                    </Form.Group>
                        <Form.Group controlId="activeHoursSwitch">
                            <OverlayTrigger
                                overlay={<Tooltip id="tooltip-activeHours">Toggle active hours - Not Implemented</Tooltip>}
                            >
                                <Form.Label>Active Hours</Form.Label>
                            </OverlayTrigger>
                            <Form.Check
                                type="switch"
                                checked={settings.activeHours}
                                onChange={(e) => handleChange('activeHours', e.target.checked)}
                            />
                        </Form.Group>
                        <Form.Group controlId="startTimeInput">
                            <OverlayTrigger
                                overlay={<Tooltip id="tooltip-startTime">Enter start time (HH:MM)</Tooltip>}
                            >
                                <Form.Label>Start Time</Form.Label>
                            </OverlayTrigger>
                            <Form.Control
                                type="time"
                                value={settings.startTime}
                                onChange={(e) => handleChange('startTime', e.target.value)}
                                disabled={!settings.activeHours}
                            />
                        </Form.Group>
                        <Form.Group controlId="stopTimeInput">
                            <OverlayTrigger
                                overlay={<Tooltip id="tooltip-stopTime">Enter stop time (HH:MM)</Tooltip>}
                            >
                                <Form.Label>Stop Time</Form.Label>
                            </OverlayTrigger>
                            <Form.Control
                                type="time"
                                value={settings.stopTime}
                                onChange={(e) => handleChange('stopTime', e.target.value)}
                                disabled={!settings.activeHours}
                            />
                        </Form.Group>
                        {!isObserver &&<Button variant="primary" onClick={handleSave}>
                            Save
                        </Button>}
                        {!isObserver &&<Button variant="danger" className="ms-2" onClick={handleShowClearModal}>
                            Clear Data
                        </Button>}
                    </Form>
                    <br/>
                    {isOperator && <LogViewer/>}
                </Col>
                {/* <Col lg={6} className="ps-0">
                    
                </Col> */}
                
            </Row>
            

            <Modal show={showClearModal} onHide={handleCloseClearModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Clear Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to clear all data including assets, missions, bus stops, and routes?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseClearModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleClear}>
                        Clear Data
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export const SettingsPageBody = memo(SettingsPageBodyMemo);
