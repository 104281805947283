import { useEffect, useRef, useState } from 'react';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons';
import { useToggle } from '../../services/General/useToggle';
import { ImageViewerBody } from './ImageViewerBody';
import { useDrivingMethod, DrivingTypes } from '../../services/Ros/Driving/DrivingProvider';
import '../../css/StreamSelector.css';  // Import the CSS file
import '../../css/path-planning.css'; 
import MissionLogBody from './MissionLogBody';
import VideoManager from './VideoManager';

export const ImageViewerOverlay = () => {
    const [show, setShow] = useToggle(false);
    const { control, setDisabled } = useDrivingMethod();
    const drivingMethod = useRef<DrivingTypes>(control);
    const [activeTab, setActiveTab] = useState<string>('assets');

    useEffect(() => {
        if (show) {
            console.log("Disabling Driving While image viewer is up.");
            drivingMethod.current = control;
            setDisabled(true);
        } else {
            console.log("Setting method back to what it was. ", drivingMethod.current);
            setDisabled(false);
        }
    }, [show]);

    return (
        <>
            <Button
                variant='info'
                size="lg"
                onClick={setShow}
            >
                <FontAwesomeIcon 
                    icon={faCameraRetro} 
                    size="lg" 
                    inverse={true}
                />
            </Button>
            <Modal
                fullscreen='lg-down'
                className='path-planning-modal'
                dialogClassName='modal-90w overflow-hidden'
                size='xl'
                centered
                show={show}
                onHide={setShow}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-dark'>Asset Viewer</Modal.Title>
                </Modal.Header>
                <Modal.Body className='ps-0 pb-0 pt-0'>
                    <Tabs
                        id="image-viewer-tabs"
                        activeKey={activeTab}
                        onSelect={(key) => key && setActiveTab(key)}
                        className="mb-3"
                    >
                        <Tab eventKey="assets" title="Assets">
                            <ImageViewerBody />
                        </Tab>
                        <Tab eventKey="mission" title="Mission Logs">
                            <div className="p-3">
                                <MissionLogBody/>
                            </div>
                        </Tab>
                        <Tab eventKey="video" title="Videos">
                            <div className="p-3">
                                <VideoManager/>
                            </div>
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>
        </>
    );
}
