import React, { useEffect, useState } from 'react';
import { Message } from 'roslib';
import { Publisher, usePublisher } from '../../services/Ros/Publisher'; // Adjust the import path as needed
import { LIGHTS_TOPIC } from '../../constants/topics';
import { TopicInterface } from '../../interfaces/Common';

interface StatusLightProps{
    light: TopicInterface
}

const FullLightState: Record<string, number> = {
    "DRIVE_NONE": 0,
    "DRIVE_FRONT": 2,
    "DRIVE_REAR": 4,
    "DRIVE_BOTH": 6,
    "RGB_OFF": 8,
    "RGB_RED": 9,
    "RGB_GREEN": 10,
    "RGB_BLUE": 12,
    "RGB_RED_GREEN": 11,
    "RGB_RED_BLUE": 13,
    "RGB_GREEN_BLUE": 14,
    "RGB_ALL": 15,
};

// // Front Light Button
// const FrontLightButton: React.FC = () => {
//     const [isFrontLightOn, setIsFrontLightOn] = useState(false); // State for front light
//     const publishFrontLight = usePublisher(); // Hook to publish front light messages

//     const handleFrontLight = () => {
//         const newState = !isFrontLightOn;
//         const message: Message = { data: newState }; // Create the ROS message with the new state
//         publishFrontLight(message); // Publish the message
//         setIsFrontLightOn(newState); // Update the local state
//         console.log('Published to /front_light:', newState);
//     };

//     return (
        
//             <button
//                 onClick={handleFrontLight}
//                 style={{
//                     backgroundColor: isFrontLightOn ? '#007bff' : 'white',
//                     color: isFrontLightOn ? 'white' : '#007bff',
//                     borderColor: '#0056b3'
//                 }}
//             >
//                 {isFrontLightOn ? 'Front Light' : 'Front Light'}
//             </button>
//     );
// };

// // Rear Light Button
// const RearLightButton: React.FC = () => {
//     const [isRearLightOn, setIsRearLightOn] = useState(false); // State for rear light
//     const publishRearLight = usePublisher(); // Hook to publish rear light messages

//     const handleRearLight = () => {
//         const newState = !isRearLightOn;
//         const message: Message = { data: newState }; // Create the ROS message with the new state
//         publishRearLight(message); // Publish the message
//         setIsRearLightOn(newState); // Update the local state
//         console.log('Published to /rear_light:', newState);
//     };

//     return (
        
//             <button
//                 onClick={handleRearLight}
//                 style={{
//                     backgroundColor: isRearLightOn ? '#007bff' : 'white',
//                     color: isRearLightOn ? 'white' : '#007bff',
//                     borderColor: '#0056b3'
//                 }}
//             >
//                 {isRearLightOn ? 'Rear Light' : 'Rear Light'}
//             </button>
//     );
// };


// const RGBLightArray: React.FC<StatusLightProps> = ({light}) => {
//     const [red, setRed] = useState(0)
//     const [green, setGreen] = useState(0)
//     const [blue, setBlue] = useState(0)
//     const publishLight = usePublisher(); // Hook to publish front light messages


//     useEffect(() => {
//         const rgbArray = {r:red,g:green,b:blue,a:1}
//         const message: Message = rgbArray; // Create the ROS message with the new state
//         publishLight(message); // Publish the message
//         console.log(`Published to ${light.name}:`, rgbArray);
//     }, [red,green,blue]);

//     const handleR = () => {
//         if (red===1){
//             setRed(0)
//         }else{
//             setRed(1)
//         }
//     }

//     const handleG = () => {
//         if (green===1){
//             setGreen(0)
//         }else{
//             setGreen(1)
//         }
//     }

//     const handleB = () => {
//         if (blue===1){
//             setBlue(0)
//         }else{
//             setBlue(1)
//         }
//     }

//     return (
//             <div>
//             <button
//                 onClick={handleR}
//                 style={{
//                     backgroundColor: red==1 ? '#007bff' : 'white',
//                     color: red==1 ? 'white' : '#007bff',
//                     borderColor: '#0056b3'
//                 }}
//             >
//                 Red
//             </button>
//             <button
//                 onClick={handleG}
//                 style={{
//                     backgroundColor: green==1 ? '#007bff' : 'white',
//                     color: green==1 ? 'white' : '#007bff',
//                     borderColor: '#0056b3'
//                 }}
//             >
//                 Green
//             </button>
//             <button
//                 onClick={handleB}
//                 style={{
//                     backgroundColor: blue==1 ? '#007bff' : 'white',
//                     color: blue==1 ? 'white' : '#007bff',
//                     borderColor: '#0056b3'
//                 }}
//             >
//                 Blue
//             </button>
//             </div>
//     );
// };

const CombinedLightArray: React.FC<StatusLightProps> = ({light}) => {
    const [red, setRed] = useState(0)
    const [green, setGreen] = useState(0)
    const [blue, setBlue] = useState(0)
    const [front, setFront] = useState(0)
    const [rear, setRear] = useState(0)
    const publishLight = usePublisher(); // Hook to publish front light messages


    useEffect(() => {
        let messageState
        if (red===1 && green===1 && blue===1){
            messageState = FullLightState["RGB_ALL"]
        }else if (red===1 && green===1 && blue===0){
            messageState = FullLightState["RCB_RED_GREEN"]
        }else if (red===1 && green===0 && blue===1){
            messageState = FullLightState["RGB_RED_BLUE"]
        }else if (red===1 && green===0 && blue===0){
            messageState = FullLightState["RGB_RED"]
        }else if (red===0 && green===1 && blue===1){
            messageState = FullLightState["RGB_GREEN_BLUE"]
        }else if (red===0 && green===1 && blue===0){
            messageState = FullLightState["RGB_GREEN"]
        }else if (red===0 && green===0 && blue===1){
            messageState = FullLightState["RGB_BLUE"]
        }else{
            messageState = FullLightState["RGB_OFF"]
        } 
        const message: Message = {data: messageState}; // Create the ROS message with the new state
        publishLight(message); // Publish the message
        console.log(`Published to ${light.name}:`, messageState);
    }, [red,green,blue]);

    useEffect(() => {
        let messageState
        if (front===1 && rear===1){
            messageState = FullLightState["DRIVE_BOTH"]
        }else if (front===1 && rear===0){
            messageState = FullLightState["DRIVE_FRONT"]
        }else if (front===0 && rear===1){
            messageState = FullLightState["DRIVE_REAR"]
        }else{
            messageState = FullLightState["DRIVE_NONE"]
        } 
        const message: Message = {data: messageState}; // Create the ROS message with the new state
        publishLight(message); // Publish the message
        console.log(`Published to ${light.name}:`, messageState);
    }, [front,rear]);


    const handleFront = () => {
        if (front===1){
            setFront(0)
        }else{
            setFront(1)
        }
    }

    const handleRear = () => {
        if (rear===1){
            setRear(0)
        }else{
            setRear(1)
        }
    }

    const handleR = () => {
        if (red===1){
            setRed(0)
        }else{
            setRed(1)
        }
    }

    const handleG = () => {
        if (green===1){
            setGreen(0)
        }else{
            setGreen(1)
        }
    }

    const handleB = () => {
        if (blue===1){
            setBlue(0)
        }else{
            setBlue(1)
        }
    }

    return (
            <div>
                <button
                onClick={handleFront}
                style={{
                    backgroundColor: front===1 ? '#007bff' : 'white',
                    color: front===1 ? 'white' : '#007bff',
                    borderColor: '#0056b3'
                }}
            >
                Front Light
            </button>
            <button
                onClick={handleRear}
                style={{
                    backgroundColor: rear===1 ? '#007bff' : 'white',
                    color: rear===1 ? 'white' : '#007bff',
                    borderColor: '#0056b3'
                }}
            >
                Rear Light
            </button>
            <br></br>
            <button
                onClick={handleR}
                style={{
                    backgroundColor: red===1 ? '#007bff' : 'white',
                    color: red===1 ? 'white' : '#007bff',
                    borderColor: '#0056b3'
                }}
            >
                Red
            </button>
            <button
                onClick={handleG}
                style={{
                    backgroundColor: green===1 ? '#007bff' : 'white',
                    color: green===1 ? 'white' : '#007bff',
                    borderColor: '#0056b3'
                }}
            >
                Green
            </button>
            <button
                onClick={handleB}
                style={{
                    backgroundColor: blue===1 ? '#007bff' : 'white',
                    color: blue===1 ? 'white' : '#007bff',
                    borderColor: '#0056b3'
                }}
            >
                Blue
            </button>
            </div>
    );
};

// Combined Light Control Buttons Component
const LightControlButtons: React.FC = () => {
    return (
        <div>
          {/* <Publisher name={FRONT_LIGHT_TOPIC.name} type="std_msgs/Bool">
            <FrontLightButton />
          </Publisher>
          <Publisher name={REAR_LIGHT_TOPIC.name} type="std_msgs/Bool">
            <RearLightButton />
          </Publisher>
          <br></br>
          <Publisher name={RGB_TOPIC.name} type={RGB_TOPIC.type}>
            <RGBLightArray light={RGB_TOPIC}/>
          </Publisher> */}

          <Publisher name={LIGHTS_TOPIC.name} type={LIGHTS_TOPIC.type}>
            <CombinedLightArray light={LIGHTS_TOPIC}/>
          </Publisher>

        </div>
    );
};

export default LightControlButtons;
