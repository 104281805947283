import { FC, useEffect, useState } from 'react';
import WidgetCard from './WidgetCard';
import WEBRTCVideo from '../../services/Video/WEBRTCVideo';
import { WEB_RTC_SERVER } from '../../constants/constants';
import { useServiceCaller } from '../../services/Ros/ServiceCaller';
import { useMsg } from '../../services/Ros/Subscriber';
import { useRealtimeUpdates } from '../../services/Requests/websocket';
import { ServiceRequest } from 'roslib';
import { useConnection } from '../../services/Ros/Connection';

interface iVideoWidget {
    id: 'front' | 'rear' | 'ptz';
    className?: string;
}

interface RobotStateMessage {
    data: number;
}

interface IUpdateMessage { 
    id: number | string;
    prog: number;
    length: number;
    run: boolean;
    start_time?: string | null | undefined;
    return_code?:string;
}


const stateMapping: Record<number, string> = {
    0: "IDLE",
    1: "UNDOCK",
    2: "READY",
    3: "DOCKING",
    4: "AUTO",
    5: "MANUAL",
    6: "NO GPS FIX",
    7: "UNDOCK",
};

const parseRobotState = (message: RobotStateMessage) => stateMapping[message.data] || "UNKNOWN";

export const VideoWidget: FC<iVideoWidget> = ({ className = "widget-bottom-left", id = "ptz" }) => {
    const [parsedState, setParsedState] = useState<string>("UNKNOWN");
    const [running, setRunning] = useState<boolean>(false);
    const [widgetId, setWidgetId] = useState<'front' | 'rear' | 'ptz'>('ptz');
    const [disabled, setDisabled] = useState<boolean>(true);
    const connection = useConnection()

    const { callService } = useServiceCaller();
    const msg = useMsg();
    const { ws, lastMessage } = useRealtimeUpdates();

    const onSocketMessage = (message: MessageEvent) => {
        const data: IUpdateMessage = JSON.parse(message.data);
        setRunning(data.run);
    };

    useEffect(() => {
        ws.addEventListener('message', onSocketMessage);
        return () => {
            ws.removeEventListener('message', onSocketMessage);
        };
    }, [ws]);

    useEffect(() => {
        if (lastMessage) {
            setRunning(JSON.parse(lastMessage.data).run);
        }
    }, [lastMessage]);

    useEffect(() => {
        const fetchRobotState = async () => {
            try {
                const request: ServiceRequest = {};
                const result = await callService(request);
                if (result.success && result.message) {
                    setParsedState(parseRobotState({ data: parseInt(result.message) } as RobotStateMessage));
                }
            } catch (error) {
                console.error("Service call failed:", error);
            }
        };
        if (connection){
            fetchRobotState();
        }
        
    }, [callService,connection]);

    useEffect(() => {
        setParsedState(parseRobotState(msg as RobotStateMessage));
    }, [msg]);

    useEffect(() => {
        console.log(parsedState, running)
        if ((running || parsedState === "MANUAL") && id !== 'ptz') {
            setDisabled(false);
            if (parsedState === "MANUAL") {
                setWidgetId(id === "rear" ? "front" : id === "front" ? "rear" : "ptz");
            } else if (["UNDOCK", "DOCKING","IDLE"].includes(parsedState)) {
                setWidgetId(id === "front" ? "rear" : id === "rear" ? "front" : "ptz");
            } else {
                setWidgetId("ptz");
            }
        } else {
            setDisabled(true);
        }
    }, [parsedState, running,id]);

    return !disabled ? (
        <WidgetCard position={className}>
            <WidgetCard.Header>{widgetId.toUpperCase()}</WidgetCard.Header>
            <WidgetCard.Body>
                <WEBRTCVideo server={WEB_RTC_SERVER} src={widgetId} />
            </WidgetCard.Body>
        </WidgetCard>
    ) : null;
};
