import {useParams} from 'react-router-dom'
import { useEffect, useState } from "react";

export const DynamicPage = () => {
  const { id } = useParams<{ id: string }>();
  const [config, setConfig] = useState({});

  useEffect(() => {
    if (id) {
      // Example: Set constants dynamically based on id
      const newConfig = getConfigForId(id);
      setConfig(newConfig);
    }
  }, [id]);

  return (
    <div>
      <h1>Dynamic Page</h1>
      <p>Current ID: {id}</p>
      <pre>{JSON.stringify(config, null, 2)}</pre>
    </div>
  );
};

// Function to return different constants based on ID
const getConfigForId = (id: string) => {
  const configs: Record<string, any> = {
    "123": { mode: "test", apiEndpoint: "/api/test" },
    "456": { mode: "production", apiEndpoint: "/api/prod" },
  };

  return configs[id] || { mode: "default", apiEndpoint: "/api/default" };
};

export default DynamicPage;
