import { useEffect, useRef, useState } from "react";
import { StopData } from "../ZoneTravel/StopTravelBody";
import { useFetch } from "../../services/Requests/useFetch";
import { useRobotGPS } from "../../services/Ros/useRobotGPS";

interface GPSPoint {
    lat: number;
    long: number;
}

const options: RequestInit = {
    method: "GET"
};

const getDistance = (point1: GPSPoint, point2: GPSPoint) => {
    const toRadians = (deg: number) => (deg * Math.PI) / 180;
    const R = 6371;
    const dLat = toRadians(point2.lat - point1.lat);
    const dLong = toRadians(point2.long - point1.long);
    
    const a = Math.sin(dLat / 2) ** 2 +
              Math.cos(toRadians(point1.lat)) * Math.cos(toRadians(point2.lat)) *
              Math.sin(dLong / 2) ** 2;
    
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
};

const getClosestStop = (robotPosition: GPSPoint, stops: StopData[] | undefined) => {
    if (!stops || stops.length === 0) return null;

    return stops.reduce((closest, stop) => {
        const stopPosition: GPSPoint = { lat: stop.gps[0], long: stop.gps[1] };
        return !closest || getDistance(robotPosition, stopPosition) < getDistance(robotPosition, { lat: closest.gps[0], long: closest.gps[1] })
            ? stop
            : closest;
    }, stops[0]);
};

export const useNearestBusStop = () => {
    const fetchStopData = useFetch<StopData[]>('/api/missions/get_stops_data', options);
    const stopDataRef = useRef<StopData[]>([]);
    const [nearestStop, setNearestStop] = useState<StopData | null>(null);
    const robotGPS = useRobotGPS();

    useEffect(() => {
        fetchStopData.get();
        return () => {
            fetchStopData.abortRequest && fetchStopData.abortRequest();
        };
    }, []);

    useEffect(() => {
        const { state: { status, data } } = fetchStopData;
        if (status === 'fetched' && data && data.length > 0) {
            stopDataRef.current = data;
        }
    }, [fetchStopData.state]);

    useEffect(() => {
        if (!robotGPS || stopDataRef.current.length === 0) return;
        
        const robotPosition: GPSPoint = { lat: robotGPS.lat, long: robotGPS.long };
        const closestStop = getClosestStop(robotPosition, stopDataRef.current);

        if (closestStop) {
            const stopPosition: GPSPoint = { lat: closestStop.gps[0], long: closestStop.gps[1] };
            const distanceToStop = getDistance(robotPosition, stopPosition);
            setNearestStop(distanceToStop <= 0.002 ? closestStop : null);
        } else {
            setNearestStop(null);
        }
    }, [robotGPS, stopDataRef.current]);

    return nearestStop;
};
