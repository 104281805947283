import { useState, useEffect, FC } from "react";
import { useFetch } from "../../services/Requests/useFetch";
import { IStopInfo, StopData } from "../../components/ZoneTravel/StopTravelBody";

const options: RequestInit = {
    method: "GET"
};

interface StopMarkerListProps extends google.maps.MarkerOptions {}

/**
 * Generates stops for the map component.
 */
export const StopMarkerList: FC<StopMarkerListProps> = (opt) => {
    const fetchMissionPlanData = useFetch<StopData[]>('/api/missions/get_stops_data', options);
    const [stops, setStops] = useState<IStopInfo>({ stops: [] });
    const [markers, setMarkers] = useState<google.maps.Marker[]>([]);

    useEffect(() => {
        fetchMissionPlanData.get();

        return () => {
            fetchMissionPlanData.abortRequest && fetchMissionPlanData.abortRequest();
        };
    }, []);

    useEffect(() => {
        const { state: { status, data } } = fetchMissionPlanData;

        if (status === 'fetched' && data && data.length > 0) {
            setStops({ stops: data });
        }
    }, [fetchMissionPlanData.state]);

    useEffect(() => {
        const zoneHighestStop: Record<string, number> = {};
        const zoneSet = new Set<string>();
    
        stops.stops.forEach((item) => {
            const zoneNumber = item.zone.substring(4);
            const stopNumber = parseInt(item.stop.substring(4), 10);
    
            zoneSet.add(zoneNumber);
    
            if (!zoneHighestStop[zoneNumber] || stopNumber > zoneHighestStop[zoneNumber]) {
                zoneHighestStop[zoneNumber] = stopNumber;
            }
        });
    
        // Determine if zone1 exists
        const hasZone1 = zoneSet.has('1');
    
        // Find the lowest zone if zone1 is missing
        const lowestZone = hasZone1 ? '1' : Array.from(zoneSet).sort((a, b) => parseInt(a) - parseInt(b))[0];

    
        const filteredStops = stops.stops.filter((item) => {
            const zoneNumber = item.zone.substring(4);
            const stopNumber = parseInt(item.stop.substring(4), 10);
            const isHome = (zoneNumber === lowestZone) && (stopNumber === 1);
            const isStop1 = (stopNumber === 1 && zoneNumber !== lowestZone);
            return (isHome || !isStop1) && stopNumber !== zoneHighestStop[zoneNumber] && zoneNumber !== '0';
        });
    
        const newMarkers: google.maps.Marker[] = [];
    
        filteredStops.forEach((item) => {
            const location = new google.maps.LatLng(item.gps[0], item.gps[1]);
            let marker;
            const zoneNumber = item.zone.substring(4);
            const stopNumber = item.stop.substring(4);
    
            if (zoneNumber === lowestZone && stopNumber === '1') {
                marker = new google.maps.Marker({
                    position: location,
                    zIndex: 999,
                    label: {
                        text: 'Home',
                        color: '#FFFFFF',
                        fontSize: '10px',
                        fontWeight: 'bold',
                        className: 'map-marker-label'
                    }
                });
            } else {
                marker = new google.maps.Marker({
                    position: location,
                    zIndex: 999,
                    label: {
                        text: `${zoneNumber}-${stopNumber}`,
                        color: '#FFFFFF',
                        fontSize: '11px',
                        fontWeight: 'bold',
                    }
                });
            }
            marker.setOptions(opt);
            newMarkers.push(marker);
        });
    
        // Clear previous markers from the map
        markers.forEach(marker => marker.setMap(null));
    
        setMarkers(newMarkers);
    
        return () => {
            newMarkers.forEach(marker => marker.setMap(null));
        };
    }, [stops]);
    

    if (!stops || stops.stops.length === 0) {
        console.log('No stops to display');
        return null;
    }

    return null; // no need to render anything as markers are being added to the map directly
};
