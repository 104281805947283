import { FC, useEffect, useState } from "react";
import { useMsg } from "../../../services/Ros/Subscriber";
import { RobotState } from "../Vitals/RobotState";
import { useServiceCaller } from "../../../services/Ros/ServiceCaller";
import { ServiceRequest } from "roslib";
import { useConnection } from "../../../services/Ros/Connection";

interface RobotStateMessage {
    data: number;
}

interface IRobotStateIndicator { 
    name: string;
}



const parseRobotState = (message: RobotStateMessage) => {
    const stateMapping: Record<number, string> = {
        0: "IDLE",
        1: "UNDOCK",
        2: "READY",
        3: "DOCKING",
        4: "AUTO",
        5: "MANUAL",
        6: "NO GPS FIX",
        7: "UNDOCK"
    };

    const stateNumber = message.data;
    const stateString = stateMapping[stateNumber] || "UNKNOWN"; // Default to "UNKNOWN" if stateNumber is not in the mapping
    return stateString;
}

export const RobotIndicator: FC<IRobotStateIndicator> = ({ name }) => {
    const [parsedState, setParsedState] = useState<string>("UNKNOWN");
    const { callService } = useServiceCaller();
    const connection = useConnection()

    const msg = useMsg();

    try {
        useEffect(() => {
            const fetchRobotState = async () => {
                const request: ServiceRequest = {};

                    const result = await callService(request);
                    if (result.success && result.message) {
                        setParsedState(parseRobotState({data: parseInt(result.message)} as RobotStateMessage))
                        console.log(result)
                    } else {
                        console.error("Service returned an unsuccessful response.");
                    }
                
            };

            if (connection){
                fetchRobotState();
            }
        }, [callService,connection]);
    } catch (error) {
        console.error("Service call failed:", error);
    }

    useEffect(() => {
        setParsedState(parseRobotState(msg as RobotStateMessage))
    }, [msg]);



    return (
        <>
            <RobotState name={name} value={parsedState}/> 
        </>
    );
}
