import React, { useEffect, useRef, useState } from 'react';
// import { encode } from 'base-64'; // Ensure this package is installed
import '../../css/RTSPViewer.css';
// import { WEB_RTC_SERVER_PSWD, WEB_RTC_SERVER_USR } from '../../constants/constants';

interface WebRTCVideoProps {
  server: string; // Expected format: http://m2:1984 or https://m2:1984
  src: 'front' | 'rear' | 'ptz';
}

const WebRTCVideo: React.FC<WebRTCVideoProps> = ({ server, src }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [activeCam, setActiveCam] = useState<RTCPeerConnection>();
  const [retryCount, setRetryCount] = useState<number>(0); // Track retries
  const [connected, setConnected] = useState<boolean>(false)

  const pcConfig: RTCConfiguration = {
    bundlePolicy: 'max-bundle' as RTCBundlePolicy,
    iceServers: [
      { urls: "stun:stun.l.google.com:19302" },
      {
        urls: "turns:turn.wrybillrobotics.com:3478",
        username: "wrybill",
        credential: "wryb1llr0b0t1cs"
      },
      {
        urls: "turn:turn.wrybillrobotics.com:3478",
        username: "wrybill",
        credential: "wryb1llr0b0t1cs"
      },
    ],
    iceCandidatePoolSize: 10
  };

  useEffect(() => {
    const connectWebRTC = async () => {
      try {
        const pc = new RTCPeerConnection(pcConfig);
        // console.log('Media:', media);

        const localTracks: MediaStreamTrack[] = [];
        const tracks = ['video', 'audio'].map(kind =>
          pc.addTransceiver(kind, { direction: 'recvonly' }).receiver.track
        );
        localTracks.push(...tracks);

        if (videoRef.current) {
          videoRef.current.srcObject = new MediaStream(localTracks);
        }

        // Encode authentication credentials
        // const username = WEB_RTC_SERVER_USR; // Replace with actual username
        // const password = WEB_RTC_SERVER_PSWD; // Replace with actual password
        // const credentials = encode(`${username}:${password}`);

        // // Fetch an authentication token before opening WebSocket
        // await fetch(`${server}/auth-check`, {
        //   method: "GET",
        //   headers: {
        //     Authorization: `Basic ${credentials}`,
        //   },
        //   credentials: "include"
        // });


        const wsProtocol = server.startsWith('https') ? 'wss' : 'ws';
        const wsUrl = `${wsProtocol}://${server.replace(/^(http|https):\/\//, '')}/api/ws?src=${src}`;
        // const wsUrl = `${wsProtocol}://${server.replace(/^(http|https):\/\//, '')}/api/ws?src=${src}&auth=${credentials}`;
        // console.log('WebSocket URL:', wsUrl);

        const ws = new WebSocket(wsUrl);

        ws.addEventListener('open', () => {
          console.log('WebSocket connection opened');
          pc.addEventListener('icecandidate', ev => {
            if (ev.candidate) {
              ws.send(JSON.stringify({ type: 'webrtc/candidate', value: ev.candidate.candidate }));
            }
          });

          pc.addEventListener("connectionstatechange", ev => {
            const connections: RTCPeerConnection = (ev.target as RTCPeerConnection);
            console.log(connections.connectionState);
            if (connections.connectionState !== "connected"){
              setConnected(false)
            }else{
              setConnected(true)
            }
            if (connections.connectionState === "failed" && retryCount < 3) { // Retry logic with limit
              console.log('Connection failed. Retrying...');
              setRetryCount(prev => prev + 1); // Increment retry count
              connectWebRTC(); // Retry the connection
            }
          });

          pc.createOffer()
            .then(offer => pc.setLocalDescription(offer))
            .then(() => {
              if (pc.localDescription) {
                ws.send(JSON.stringify({ type: 'webrtc/offer', value: pc.localDescription.sdp }));
              }
            })
            .catch(error => console.error('Error creating offer:', error));
        });

        ws.addEventListener('message', ev => {
          const msg = JSON.parse(ev.data);
          if (msg.type === 'webrtc/candidate') {
            pc.addIceCandidate(new RTCIceCandidate({ candidate: msg.value, sdpMid: '0' }))
              .catch(error => console.error('Error adding ICE candidate:', error));
          } else if (msg.type === 'webrtc/answer') {
            pc.setRemoteDescription(new RTCSessionDescription({ type: 'answer', sdp: msg.value }))
              .catch(error => console.error('Error setting remote description:', error));
          }
        });

        ws.addEventListener('error', error => console.error('WebSocket error:', error));
        ws.addEventListener('close', () => console.log('WebSocket connection closed'));

        setActiveCam(pc);
      } catch (error) {
        console.error('Error in connect function:', error);
      }
    };

    if (activeCam) {
      activeCam.close();
      setConnected(false)
      console.log('Closing active cam');
    }

    connectWebRTC();
  }, [server, src, retryCount]); // Retry count dependency

  return <div className="fullscreen-video"><video id="video" ref={videoRef} autoPlay controls={!connected} muted /></div>;
};

export default WebRTCVideo;
