import React, { useEffect, useRef, useState } from 'react';
import useDeepCompareEffect from '../../services/General/useDeepCompareEffect';

interface GoogleMapProps extends google.maps.MapOptions {
    id: string;
    onClick?: (e: google.maps.MapMouseEvent) => void; // Add onClick prop
}

// Define your constant polygon points here
const POLYGON_POINTS: google.maps.LatLngLiteral[] = [
    { lat: -38.61220678, lng: 176.1936268 },
    { lat: -38.61222965, lng: 176.1935982 },
    { lat: -38.61225467, lng: 176.1936058 },
    { lat: -38.61235342, lng: 176.1936624 },
    { lat: -38.61236931, lng: 176.1936743 },
    { lat: -38.612442, lng: 176.1937572 },
    { lat: -38.61280287, lng: 176.1932966 },
    { lat: -38.61271791, lng: 176.1931735 },
    { lat: -38.61283941, lng: 176.1929944 },
    { lat: -38.61287805, lng: 176.1930312 },
    { lat: -38.61298877, lng: 176.1928928 },
    { lat: -38.61292803, lng: 176.1928192 },
    { lat: -38.61286654, lng: 176.1927354 },
    { lat: -38.61276817, lng: 176.1926037 },
    { lat: -38.61271214, lng: 176.1925266 },
    { lat: -38.61253821, lng: 176.1922899 },
    { lat: -38.6125168, lng: 176.1922868 },
    { lat: -38.61241468, lng: 176.1924184 },
    { lat: -38.61237838, lng: 176.192463 },
    { lat: -38.61234847, lng: 176.192496 },
    { lat: -38.61223688, lng: 176.1926336 },
    { lat: -38.61214269, lng: 176.1927398 },
    { lat: -38.61208808, lng: 176.1928025 },
    { lat: -38.61205801, lng: 176.1928492 },
    { lat: -38.6120985, lng: 176.1929133 },
    { lat: -38.61252371, lng: 176.1923872 },
    { lat: -38.61268428, lng: 176.1926062 },
    { lat: -38.61269315, lng: 176.1926305 },
    { lat: -38.6126881, lng: 176.192655 },
    { lat: -38.61256802, lng: 176.1928125 },
    { lat: -38.61265718, lng: 176.1929335 },
    { lat: -38.61259513, lng: 176.1930209 },
    { lat: -38.61247057, lng: 176.1931726 },
    { lat: -38.61240508, lng: 176.193083 },
    { lat: -38.61235489, lng: 176.1931414 },
    { lat: -38.61242989, lng: 176.1932415 },
    { lat: -38.61249965, lng: 176.1933285 },
    { lat: -38.6125726, lng: 176.193422 },
    { lat: -38.61247089, lng: 176.193551 },
    { lat: -38.61244337, lng: 176.1935671 },
    { lat: -38.61236861, lng: 176.1934724 },
    { lat: -38.61230331, lng: 176.1935354 },
    { lat: -38.61226962, lng: 176.1934983 },
    { lat: -38.61233134, lng: 176.1934149 },
    { lat: -38.61222142, lng: 176.1932639 },
    { lat: -38.61218493, lng: 176.19331 },
    { lat: -38.61225721, lng: 176.1934164 },
    { lat: -38.61216999, lng: 176.1935214 },
    { lat: -38.6121309, lng: 176.1935628 },
    { lat: -38.61211349, lng: 176.1935816 },
    { lat: -38.61203364, lng: 176.1934654 },
    { lat: -38.61193463, lng: 176.1935797 },
    { lat: -38.61193045, lng: 176.1935828 },
    { lat: -38.61192338, lng: 176.1935866 },
    { lat: -38.61191406, lng: 176.1935891 },
    { lat: -38.61187489, lng: 176.1936426 },
    { lat: -38.61194938, lng: 176.1937395 },
    { lat: -38.61194902, lng: 176.1937764 },
    { lat: -38.61187536, lng: 176.193849 },
    { lat: -38.61178663, lng: 176.1937576 },
    { lat: -38.61175798, lng: 176.193788 },
    { lat: -38.6117268, lng: 176.1937958 },
    { lat: -38.61169784, lng: 176.1937583 },
    { lat: -38.61168644, lng: 176.1937507 },
    { lat: -38.61166762, lng: 176.1937251 },
    { lat: -38.61166472, lng: 176.1937148 },
    { lat: -38.61160136, lng: 176.1936359 },
    { lat: -38.6115867, lng: 176.1936258 },
    { lat: -38.61157567, lng: 176.1936136 },
    { lat: -38.61156296, lng: 176.1935913 },
    { lat: -38.61156065, lng: 176.1935527 },
    { lat: -38.61157585, lng: 176.1935004 },
    { lat: -38.6116302, lng: 176.19341 },
    { lat: -38.61166682, lng: 176.193315 },
    { lat: -38.61169735, lng: 176.193217 },
    { lat: -38.61174096, lng: 176.1931933 },
    { lat: -38.61176885, lng: 176.1931831 },
    { lat: -38.61179304, lng: 176.1931653 },
    { lat: -38.611822, lng: 176.193134 },
    { lat: -38.61185408, lng: 176.1930835 },
    { lat: -38.61186601, lng: 176.1930509 },
    { lat: -38.61187622, lng: 176.1930043 },
    { lat: -38.61188654, lng: 176.1929138 },
    { lat: -38.61184849, lng: 176.1928021 },
    { lat: -38.61177876, lng: 176.1926815 },
    { lat: -38.61178121, lng: 176.1926887 },
    { lat: -38.61169876, lng: 176.1925388 },
    { lat: -38.61159022, lng: 176.1922388 },
    { lat: -38.61156458, lng: 176.1921114 },
    { lat: -38.61148669, lng: 176.1920861 },
    { lat: -38.61143905, lng: 176.1924565 },
    { lat: -38.61135358, lng: 176.1924197 },
    { lat: -38.6112722, lng: 176.1922827 },
    { lat: -38.61121618, lng: 176.1922595 },
    { lat: -38.61119248, lng: 176.192348 },
    { lat: -38.61100233, lng: 176.1925383 },
    { lat: -38.61106143, lng: 176.1926469 },
    { lat: -38.61128252, lng: 176.1925013 },
    { lat: -38.61130713, lng: 176.1925162 },
    { lat: -38.61133757, lng: 176.1925422 },
    { lat: -38.61135919, lng: 176.1925891 },
    { lat: -38.61143087, lng: 176.1925783 },
    { lat: -38.61144893, lng: 176.1926354 },
    { lat: -38.61145011, lng: 176.1926782 },
    { lat: -38.61145453, lng: 176.1927158 },
    { lat: -38.61139711, lng: 176.1927591 },
    { lat: -38.61145383, lng: 176.1928917 },
    { lat: -38.6115005, lng: 176.1928555 },
    { lat: -38.61147186, lng: 176.1927745 },
    { lat: -38.61151411, lng: 176.1927441 },
    { lat: -38.61156441, lng: 176.1928541 },
    { lat: -38.61157843, lng: 176.1928446 },
    { lat: -38.61158746, lng: 176.1928627 },
    { lat: -38.61157466, lng: 176.1928739 },
    { lat: -38.61162501, lng: 176.1930021 },
    { lat: -38.61162726, lng: 176.1930316 },
    { lat: -38.61162309, lng: 176.1930518 },
    { lat: -38.61159726, lng: 176.1930696 },
    { lat: -38.61157662, lng: 176.19303 },
    { lat: -38.61148474, lng: 176.1931035 },
    { lat: -38.61155502, lng: 176.1932738 },
    { lat: -38.61158552, lng: 176.1932388 },
    { lat: -38.6116172, lng: 176.1932888 },
    { lat: -38.61161847, lng: 176.1932965 },
    { lat: -38.6116145, lng: 176.1933082 },
    { lat: -38.61160931, lng: 176.1933196 },
    { lat: -38.61159308, lng: 176.1933464 },
    { lat: -38.61157865, lng: 176.1933685 },
    { lat: -38.61156861, lng: 176.1933823 },
    { lat: -38.61155354, lng: 176.1934008 },
    { lat: -38.61148052, lng: 176.1934242 },
    { lat: -38.61140446, lng: 176.1935236 },
    { lat: -38.61152288, lng: 176.1936852 },
    { lat: -38.61190762, lng: 176.1941936 },
    { lat: -38.61188279, lng: 176.1942634 },
    { lat: -38.61214365, lng: 176.1945941 },
    { lat: -38.61217643, lng: 176.1945945 },
    { lat: -38.61226305, lng: 176.1947118 },
    { lat: -38.61252611, lng: 176.1943986 },
    { lat: -38.61237255, lng: 176.1942223 },
    { lat: -38.61229652, lng: 176.1943109 },
    { lat: -38.61213026, lng: 176.1944597 },
    { lat: -38.61210294, lng: 176.1944666 },
    { lat: -38.61209109, lng: 176.1944647 },
    { lat: -38.61208264, lng: 176.1944591 },
    { lat: -38.6119664, lng: 176.1943067 },
    { lat: -38.61195607, lng: 176.1942903 },
    { lat: -38.61195382, lng: 176.1942696 },
    { lat: -38.61200493, lng: 176.1941536 },
    { lat: -38.61196936, lng: 176.1940962 },
    { lat: -38.61194459, lng: 176.1940612 },
    { lat: -38.61196145, lng: 176.1940161 },
    { lat: -38.61213938, lng: 176.1937937 },
    { lat: -38.61219241, lng: 176.1938566 },
    { lat: -38.61221268, lng: 176.1938271 },
    { lat: -38.61216174, lng: 176.1937536 },
    { lat: -38.61221255, lng: 176.19369 },
    { lat: -38.61217124, lng: 176.1936313 },
    { lat: -38.61217379, lng: 176.1936272 },
    { lat: -38.61217763, lng: 176.1936234 },
    { lat: -38.61220678, lng: 176.1936268 }
];

export const GoogleMap: React.FC<GoogleMapProps> = ({ children, id, onClick, ...options }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();

    useEffect(() => {
        if (window.google && ref.current && !map) {
            const mapInstance = new window.google.maps.Map(ref.current, options);
            setMap(mapInstance);  // Only set the map once when it is initialized
    
            if (onClick) {
                mapInstance.addListener('click', onClick);
            }
        }
    }, [map, options, onClick]);
    

    useDeepCompareEffect(() => {
        if (map) {
            map.setOptions(options);
        }
    }, [map, options]);

    function getBoundingBox(centerLat: number, centerLng: number, distanceKm: number): google.maps.LatLngLiteral[] {
        
        // Convert km to degrees
        const deltaLat = distanceKm / 111.32;
        const deltaLng = distanceKm / (111.32 * Math.cos(centerLat * (Math.PI / 180)));
    
        // Calculate the 1km by 1km bounding box
        const northLat = centerLat + deltaLat;
        const southLat = centerLat - deltaLat;
        const eastLng = centerLng + deltaLng;
        const westLng = centerLng - deltaLng;

        const bounds: google.maps.LatLngLiteral[] = [
            { lat: northLat, lng: westLng },
            { lat: northLat, lng: eastLng },
            { lat: southLat, lng: eastLng },
            { lat: southLat, lng: westLng },
        ];
    
        return bounds
    }
    

    useEffect(() => {
        if (map) {
            // Example usage:
            const centerLat = -38.6124739819353; // Example center latitude
            const centerLng = 176.1932653085561; // Example center longitude
            const distanceKm = 1; // 1 km by 1 km box

            const boundingBox = getBoundingBox(centerLat, centerLng, distanceKm);
            // Now you can use the `map` directly instead of `map.current`
            // const outerBounds: google.maps.LatLngLiteral[] = boundingBox

            const outerBounds: google.maps.LatLngLiteral[] = [
                { lat: -38.6090, lng: 176.1930}, 
                { lat: -38.6130, lng: 176.1890 },  
                { lat: -38.6160, lng: 176.1940 },
                { lat: -38.6120, lng: 176.1990},
            ];
            console.log(boundingBox,outerBounds);

            const inversePolygon = new google.maps.Polygon({
                paths: [outerBounds, POLYGON_POINTS],
                strokeColor: '#DD1111',
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: '#DD1111',
                fillOpacity: 0.25,
            });
    
            inversePolygon.setMap(map); // Use `map` directly
            
            return () => {
                inversePolygon.setMap(null);
            };
        }
    }, [map]); // Ensure the effect runs when `map` is set
    

    return (
        <div ref={ref} style={{ width: "100%", height: "100%" }} id={id}>
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, { map });
                }
            })}
        </div>
    );
}
