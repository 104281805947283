import React from 'react';
import { render } from 'react-dom';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { AppRoute } from './utilities/http/routing/AppRoute';
import { NotFound } from './components/Pages/NotFound';
import { HomePage } from './components/Pages/HomePage';
import { DynamicPage } from './components/Pages/DynamicPage';
import Logout from './utilities/http/routing/Logout'; // Import the Logout component
import './css/main.css';
import './css/driving-controls.css';
import './css/path-planning.css';
import './css/video.css';
import './css/widgets.css';
import './css/robot-status.css';
import './css/toolbars.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ScreenResolutionProvider } from './services/General/ScreenResolutionProvider';
import AuthCallback from './utilities/http/routing/AuthCallback'; // Import the callback component
import { AuthProvider } from './utilities/http/routing/AuthContext';
import { ObserverProvider } from './utilities/http/routing/ObserverContext';
import { OperatorProvider } from './utilities/http/routing/OperatorContext';
const MainLayout = props => (
  <>
    {props.children}
  </>
);

render((
  <BrowserRouter>
    <AuthProvider>
      <OperatorProvider>
        <ObserverProvider>
          <React.StrictMode>
            <Switch>
              <ScreenResolutionProvider>
                <AppRoute exact path="/" layout={MainLayout} component={HomePage} />
                <Route path="/robot/:id" component={DynamicPage} />
                <Route path="/auth/callback" component={AuthCallback} />
                <Route path="/logout" component={Logout} />
                <AppRoute layout={MainLayout} component={NotFound}/>
              </ScreenResolutionProvider>
            </Switch>
          </React.StrictMode>
        </ObserverProvider>
      </OperatorProvider>
    </AuthProvider>
  </BrowserRouter>
), document.getElementById('root'));
